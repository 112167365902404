import { BASE_URL } from "../../constants";
import api from "../../lib/axios";
import { fetchTasksRequest, fetchTasksSuccess } from "../reducers/tasks/taskSlice";
import { AppThunk } from "../store/configureStore";

export const fetchTasks = (currentPage: number): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchTasksRequest());

    const response = await api.get(`${BASE_URL}/tasks/list/?page=${currentPage}`);
    dispatch(fetchTasksSuccess(response.data))
  } catch (error) {

  }
}

export const filterTasks = (currentPage: number, body: string): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchTasksRequest());
    let body_json = JSON.parse(body)
    const response = await api.post(`${BASE_URL}/tasks/filter/?page=${currentPage}`, body_json);
    dispatch(fetchTasksSuccess(response.data));
  
  } catch (error) {
    
  }
}