
import React, { useState } from 'react'

interface DateInputFieldProps {
  // id: string;
  name_: string;
  label: string;
  value: string;
  required?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DateInputField: React.FC<DateInputFieldProps> = ({ name_, label, value, onChange }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("target", event.target)
    setSelectedDate(new Date(event.target.value));
    onChange(event);
    // setShowDatePicker(false);
  };

  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    const [month, day, year] = formattedDate.split('/');
    return `${year}-${month}-${day}`
  };

  const handleToday = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate());
    setSelectedDate(tomorrow)
    const formattedDate = formatDate(tomorrow); // Assuming you have a function to format the date
    onChange({ target: { name: name_, value: formattedDate } } as React.ChangeEvent<HTMLInputElement>);
    setShowDatePicker(false);
  };

  const handleTomorrow = () => {
    const today = new Date();
    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(today.getDate() + 1);
    setSelectedDate(dayAfterTomorrow)
    const formattedDate = formatDate(dayAfterTomorrow); // Assuming you have a function to format the date
    onChange({ target: { name: name_, value: formattedDate } } as React.ChangeEvent<HTMLInputElement>);
    setShowDatePicker(false);
  };

  return (
    <div className="flex flex-col">
      <label className="font-medium dark:text-emerald-200">
        {label}
      </label>
      <div className="relative">
        <input
          type="text"
          value={selectedDate ? formatDate(selectedDate) : value}
          name={name_}
          readOnly
          placeholder="Select date"
          onChange={() => {}}
          onClick={() => setShowDatePicker(!showDatePicker)}
          className="bg-blue-50 dark:bg-slate-900 dark:text-slate-300 border-2 border-blue-200 flex-grow min-w-[280px] rounded-lg py-2 px-3 my-2 cursor-pointer"
        />
        {showDatePicker && (
          <div className="absolute top-10 left-0 z-10 bg-white shadow-md border border-gray-300 rounded min-w-[280px] p-2">
            <div className="flex justify-around mb-2">
              <button onClick={handleToday} className="text-blue-500">
                Today
              </button>
              <button onClick={handleTomorrow} className="text-blue-500">
                Tomorrow
              </button>
            </div>
            {/* You can replace this part with your calendar implementation */}
            <input
              type="date"
              value={value}
              name={name_}
              onChange={(e) => handleDateChange(e)}
              className="border border-gray-300 rounded px-2 py-1 w-full"
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface Props {
  // id: string;
  name_: string;
  label: string;
  type: string;
  placeholder: string;
  value: string;
  required?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface InputProductProps {
  // id: string;
  name_: string;
  label: string;
  index: number;
  type: string;
  placeholder: string;
  value: string;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField = ({ name_, label, type, placeholder, value, required, onChange}: Props) => {
  return (
    <div className="flex flex-col">
    <label className="font-medium dark:text-emerald-200">
      {label}
      {required && <span className="text-red-500 px-1">*</span>}
    </label>
    <input
      type={type}
      value={value}
      name={name_}
      placeholder={placeholder}
      required={required}
      onChange={onChange}
      className="bg-blue-50 dark:bg-slate-900 dark:text-slate-300 border-2 border-blue-200 flex-grow min-w-[280px] rounded-lg py-2 px-3 my-2"
    />
  </div>
  )
}

export const InputProductField = ({ name_, label, index, type, placeholder, value, required, onChange}: InputProductProps) => {
  return (
    <div className="flex flex-col">
      <label className="font-medium dark:text-emerald-200">
      {label}
      {required && <span className="text-red-500">*</span>}
    </label>
    <input
      type={type}
      data-index={index}
      value={value}
      name={name_}
      placeholder={placeholder}
      required={required}
      onChange={onChange}
      className="bg-blue-50 dark:bg-slate-900 dark:text-slate-300 border-2 border-blue-200 flex-grow min-w-[280px] rounded-lg py-2 px-3 my-2"
    />
  </div>
  )
}

export default InputField


