import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/Elements/Loader/Loader';
import { LoginFn, resetPasswordFn } from '../../lib/auth';
import Cropway from "../../assets/logo/Cropway.png"
import { showErrorMessage, showMessage } from '../../components/Elements/Message';

const ForgotPasswordPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>("")
  const navigate = useNavigate();

  interface ErrorResponse {
    message: string;
  }

  type SuccessMessage ={
    success: string;
    token: string;
    uid: string;
  }

  interface ApiError {
    error: string;
    detail: string;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //  login logic here
    setLoading(true)
    try {
      const response: SuccessMessage = await resetPasswordFn({email})
      console.log("responseLogin: ", response)
      showMessage(response.success)
      // navigate('/app/leads');
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const apiError = error.response?.data as ApiError;
        console.log("apiError type: ", typeof(apiError))
        console.log("apiError: ", apiError)
        showErrorMessage(apiError)
        
        // Handle API error...
      } else {
        // Handle other types of errors...
      }
      
    } finally {
      setLoading(false)
    }
  };

  if (error) {
    // <ErrorMessage message= "Error Error Error"/>
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img src={Cropway} alt="cropway_logo" className="w-28 dark:bg-slate-100 rounded-lg mx-auto my-5" />
          <h2 className="text-center text-3xl font-extrabold text-gray-900 dark:text-teal-400">
            CRM App
          </h2>
        </div>
        <div>
          <h2 className="text-center text-3xl font-extrabold text-gray-900 dark:text-rose-400">
            Password Reset
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input className="dark:bg-black" type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only dark:text-black">
                email
              </label>
              <input
                id="email"
                name="email"
                type="text"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 dark:bg-gray-700 dark:text-gray-300 dark:placeholder-gray-300  rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="flex items-center justify-end">
            <div className="text-sm">
              <a href="/auth/login/" className="font-medium text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-200">
                Remember your password?
              </a>
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled=  {loading}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              
              <svg
                className="h-5 w-5 text-blue-500 group-hover:text-blue-400 transition ease-in-out duration-150"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                >
                <path
                  fillRule="evenodd"
                  d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                />
                <path
                  fillRule="evenodd"
                  d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-6a1 1 0 00-1 1v3a1 1 0 102 0V5a1 1 0 00-1-1zm-1 10a1 1 0 100 2 1 1 0 000-2z"
                />
              </svg>
              <span className="ml-3">Reset</span>
            </button>
          </div>
          <div className='flex justify-end'>
            <div className="text-sm">
              <Link to="/auth/register/" className="font-medium text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-200">
                Don't have an account yet? Create now.
              </Link>
            </div>
          </div>
        </form>
        <Loader show={loading} />
      </div>
    </div>
  )}

  export default ForgotPasswordPage;