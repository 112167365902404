import React from 'react'

type Props = {
  title: string;
  // name: string;
  value: string;
}

const PlaceholderField = ({title, value}: Props) => {
  return (
    <div className="flex flex-col">
      <label className="font-medium dark:text-emerald-200">{title}</label>
      <label className="border-2 bg-cyan-100 border-blue-200 rounded-lg min-w-[280px] w-full  py-2 px-3 my-2">{value ? value : "N/A"}</label>
    </div>
  )
}

export default PlaceholderField
