export const fields_profile = [
  {
    name_: 'avatar',
    label: 'Avatar',
    type: 'file',
  },
  {
    name_: 'first_name',
    label: 'First Name',
    type: 'text',
  },
  {
    name_: 'last_name',
    label: 'Last Name',
    type: 'text',
  },
  {
    name_: 'phone',
    label: 'Phone',
    type: 'tel',
  },
  {
    name_: 'alt_phone',
    label: 'Alternate Phone',
    type: 'tel',
  },
  {
    name_: 'email',
    label: 'Email',
    type: 'email',
  },
]