import { useLocation, useNavigate, useRoutes } from "react-router-dom";

import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";
import storage from "../utils/storage";
import LandingPage from "../pages/Landing Page/Landing";
import NotFoundPage from "../pages/404Page/NotFoundPage";

export const AppRoutes = () => {
  const tokenString = storage.getToken();
  const tokens = tokenString ? JSON.parse(tokenString) : null;
  const isAccess = tokens ? tokens.access : null
  const auth = Boolean(isAccess);
  const navigate = useNavigate();
  const location = useLocation();

  const commonRoutes = [{path: '/', element: <LandingPage auth={auth} />}]

  const routes = auth ? protectedRoutes: publicRoutes;
  
  const element = useRoutes([
    ...routes, 
    ...commonRoutes,
  {
    path: '*',
    element: <NotFoundPage />,
  },
  ]);

  // Check if the user is logged in but tries to access the login page
  if (auth && location.pathname === '/auth/login') {
    navigate(-1); // Redirect back to the previous page
    return null; // Return null to prevent rendering the 404 page
  }

  return <>{element}</>
} 