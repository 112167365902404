import React, { ChangeEvent, useEffect, useState } from 'react';

type ImageFieldProps = {
  name: string;
  label: string;
  value: File | string | null | undefined;
  // onChange: (file: File | null) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ImageField: React.FC<ImageFieldProps> = ({ name, label,value, onChange }) => {
  const [fileName, setFileName] = useState('');

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    // if (file) {
    //   setFileName(file.name);
    //   onChange(file);
    // } else {
    //   setFileName('');
    //   onChange(null);
    // }
    onChange(e)
  };

  return (
    <div className="flex flex-col items-center mx-8">
      <div className="w-64 h-64 rounded-full overflow-hidden border border-gray-300 my-4">
        {/* Display uploaded image or placeholder */}
        {value instanceof File ? (
          <img src={URL.createObjectURL(value)} alt='fileName' className="w-full h-full object-cover" />
        ) : value ? (
          <img src={value} alt='fileName' className="w-full h-full object-cover" />
        ) : null}

      </div>
      <div className="flex flex-col items-center ml-4">
        <label htmlFor={name} className="my-2 block text-lg font-semibold dark:text-slate-300">
          {label}
        </label>
        <div className="">
          <label className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 cursor-pointer">
            Browse
            <input
              name='avatar'
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default ImageField;
