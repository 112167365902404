// FormLayout.js
import React from 'react';

type Props = {
  title: string | undefined;
  children: React.ReactNode;
};

const FormLayout = ({ title, children }: Props) => {
  return (
    <div className="flex justify-center items-center">
      <div className="bg-blue-200 dark:bg-slate-600 rounded-lg shadow-lg p-5">
        <h2 className="text-lg font-medium mb-5 dark:text-slate-300">{title}</h2>
        <div className="flex flex-wrap gap-5">{children}</div>
      </div>
    </div>
  );
};

export default FormLayout;
