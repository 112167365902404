import { Task } from '../../../features/tasks'

export type fieldTypeTask = {
  name_: keyof Task; 
  label: string; 
  type: string, 
  options?: {
    id?: number;
    value: string;
    label: string;
  }[],
  required?: boolean;
}

export const fields_create: fieldTypeTask[] = [
  {
    name_: 'title',
    label: 'Task Name',
    type: 'text',
    required: true
  },
  {
    name_: 'description',
    label: 'Description',
    type: 'text',
  },
  {
    name_: 'status',
    label: 'Status',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'new', label: 'New' },
      { id: 2, value: 'in_progress', label: 'In Progress' },
      { id: 3, value: 'completed', label: 'Completed' },
    ],
  },
  {
    name_: 'priority',
    label: 'Priority',
    type: 'select',
    options: [
      { value: '', label: 'Select an option' },
      { value: 'low', label: 'Low' },
      { value: 'medium', label: 'Medium' },
      { value: 'high', label: 'High' },
    ],
  },
  {
    name_: 'due_date',
    label: 'Due Date',
    type: 'date',
  },
  {
    name_: 'assigned_to',
    label: 'Assign To',
    type: 'selectMulti',
    options: [
      { id: 0, value: '', label: 'Select an option' },
    ],
  },
];

export const fields: fieldTypeTask[] = [
  {
    name_: 'title',
    label: 'Task Name',
    type: 'text',
    required: true
  },
  {
    name_: 'description',
    label: 'Description',
    type: 'text',
  },
  {
    name_: 'status',
    label: 'Status',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'new', label: 'New' },
      { id: 2, value: 'in_progress', label: 'In Progress' },
      { id: 3, value: 'completed', label: 'Completed' },
    ],
  },
  {
    name_: 'priority',
    label: 'Priority',
    type: 'select',
    options: [
      { value: '', label: 'Select an option' },
      { value: 'low', label: 'Low' },
      { value: 'medium', label: 'Medium' },
      { value: 'high', label: 'High' },
    ],
  },
  {
    name_: 'due_date',
    label: 'Due Date',
    type: 'date',
  },
  {
    name_: 'assigned_to',
    label: 'Assign To',
    type: 'selectMulti',
    options: [
      { id: 0, value: '', label: 'Select an option' },
    ],
  },
  {
    name_: 'created_by',
    label: 'Created By',
    type: 'placeholder',
  },
  {
    name_: 'updated_by',
    label: 'Updated By',
    type: 'placeholder',
  },
];