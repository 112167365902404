import { useContext } from "react";
import { AuthContext } from "../../providers/AuthContext";
import { ArrowRightOnRectangleIcon, HomeIcon, ChartBarIcon, UserGroupIcon, ArrowLeftCircleIcon, SunIcon, MoonIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { To } from 'react-router-dom';
import { MouseEvent } from "react";
import Tooltip from "../Elements/Tooltip/Tooltip";
import { logoutFn } from "../../lib/auth";

type Props = {
  isOpen: boolean
}

export const SidebarLinks = ({isOpen} : Props) => {
  const navigate = useNavigate()
  const authContext = useContext(AuthContext);

  const onLogout = () => {
    logoutFn()
    navigate("/")
  }

  type linksProp = {
    to: To
    icon: JSX.Element
    text: string
    onclick?: (event: MouseEvent<HTMLAnchorElement>) => void | undefined
  }

  const links: linksProp[] = [
    {
      to: "/",
      icon: <HomeIcon className="w-5 h-5 mr-2 dark:text-slate-200" />,
      text: "Home"
    },
    {
      to: "/app/profile",
      icon: <UserCircleIcon className="w-5 h-5 mr-2 dark:text-slate-200" />,
      text: "Profile"
    },
    {
      to: "/app/dashboard",
      icon: <ChartBarIcon className="w-5 h-5 mr-2 dark:text-slate-200" />,
      text: "Dashboard"
    },
    {
      to: "/app/leads",
      icon: <UserGroupIcon className="w-5 h-5 mr-2 dark:text-slate-200" />,
      text: "Leads"
    },
    {
      to: authContext?.auth ? "/" : "/auth/login",
      icon: authContext?.auth ? <ArrowLeftCircleIcon className="w-5 h-5 mr-2 dark:text-slate-200" /> : <ArrowRightOnRectangleIcon className="w-5 h-5 mr-2 dark:text-slate-200" />,
      text: authContext?.auth ? "Logout" : "Login",
      onclick: authContext?.auth ? onLogout : undefined
    },
    // {
    //   onclick: toggleDarkMode,
    //   icon: (!darkMode as boolean) ? <SunIcon className="w-4 h-4 m-1" /> : <MoonIcon className="w-4 h-4 m-1"/>,
    //   text: darkMode ? "Light Mode" : "Dark Mode",
    //   to: ""
    // }
  ];

  type componentProps = {
    link: linksProp;
    index: number;
    disabled: boolean
  }

  const Component = ({link, index, disabled}: componentProps) => {
    return (
      <Tooltip text={link.text} disabled={disabled}>
        <Link
        key={index}
        to={link.to}
        className="flex items-center pl-4 py-2 mb-2 text-gray-900 rounded-md hover:bg-sky-300 dark:hover:bg-gray-700"
        onClick={link.onclick}
        >
          {link.icon}
          <span className={`text-gray-900 dark:text-gray-200 duration-500 ${!isOpen && 'hidden'}`}>
            {link.text}
          </span>
        </Link>
      </Tooltip>
    )
  }

  return (
    <nav className={`flex flex-col p-4 mx-auto`}>
      {links.map((link, index: number) => (
        isOpen ? (
        <Component link={link} index={index} disabled={true} />
        ) : 
        <Component link={link} index={index} disabled={false} />
      ))}
    </nav>
  );
};
