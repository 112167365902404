import React, { ChangeEvent, FormEvent, useEffect } from 'react'
import InputField from '../InputField'
import ImageField from '../ImageField'
import { ProfileType } from '../../../features/profile/api'
import PlaceholderField from '../PlaceholderField'

type Props = {
  profile: ProfileType | null;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const ProfileForm = ({profile, handleSubmit, onChange}: Props) => {
  

  useEffect(() => {
    if (profile){
      console.log("profile", profile)
    }
  }, [profile])

  
  return (
    <form onSubmit={handleSubmit}>
        <div className="md:grid grid-flow-col">
          <ImageField
            name="avatar"
            label="Avatar"
            value={profile?.avatar}
            onChange={onChange}
          />
          <div className='md:grid grid-flow-row sm:mt-0 mt-8'>
          {/* <div className="my-4 mx-8">
            <label htmlFor="name" 
                className="block text-lg font-semibold dark:text-slate-300">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={`w-full p-2 rounded border dark:bg-slate-700 ${
                errors.name ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {errors.name && <p className="text-red-500 mt-1">{errors.name}</p>}
          </div> */}

          <InputField 
            name_="first_name"
            label="First Name"
            type="text"
            placeholder="Enter first name"
            value={profile?.first_name?.toString() ?? ""}
            onChange={onChange}
          />
          <InputField 
            name_="last_name"
            label="Last Name"
            type="text"
            placeholder="Enter last name"
            value={profile?.last_name?.toString() ?? ""}
            onChange={onChange}
          />
          <InputField 
            name_="Phone"
            label="Phone"
            type="text"
            placeholder="Enter Phone"
            value={profile?.phone?.toString() ?? ""}
            onChange={onChange}
          />
          <InputField 
            name_="alt_phone"
            label="Alternate Phone"
            type="text"
            placeholder="Enter alternate phone"
            value={profile?.alt_phone?.toString() ?? ""}
            onChange={onChange}
          />
          <PlaceholderField 
            title="Email"
            value={profile?.email?.toString() ?? ""}
            // onChange={onChange}
          />

          </div>
        </div>
        
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 my-4"
          >
            Submit
          </button>
        </div>
      </form>
  )
}