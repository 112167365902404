import React, { useContext } from 'react';
import { ThemeContext } from '../Theme/themeContext';
import Cropway from "../../assets/logo/Cropway.png"
import { logoutFn } from '../../lib/auth';
import { useNavigate } from 'react-router-dom';

// interface HeaderProps {
//   loggedIn: boolean;
//   // onLogin: () => void;
//   // onLogout: () => void;
//   // darkMode: boolean;
//   // onDarkModeToggle: () => void;
//   // language: string;
//   // onLanguageToggle: () => void;
// }

const Header: React.FC = () => {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext)
  const loggedIn = true
  const navigate = useNavigate()
  const onLogin = () => {

  }

  const onLogout = () => {
    logoutFn()
    navigate("/")
  }

  return (
    <header className="bg-blue-300 dark:bg-gray-800">
      <div className="container mx-auto py-4 flex items-center justify-between">
        <div className="flex items-center mx-4">
          <img src={Cropway} alt="cropway_logo" className="h-8 w-8 dark:bg-white rounded-lg" />
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white ml-2">CRM App</h1>
        </div>
        <div className={`items-center hidden md:block `}>
          {loggedIn ? (
            <button
              className="text-gray-800 dark:text-white bg-transparent border border-gray-700 rounded py-2 px-4 hover:bg-white dark:hover:bg-gray-300 hover:text-gray-800 dark:hover:text-gray-900"
              onClick={onLogout}
            >
              Logout
            </button>
          ) : (
            <button
              className="text-gray-800 dark:text-white bg-transparent border border-gray-700 dark:border-white rounded py-2 px-4 hover:bg-white hover:text-gray-800"
              onClick={onLogin}
            >
              Login
            </button>
          )}
          <button
            className={`ml-4 text-gray-800 dark:text-white bg-transparent border border-gray-700 hover:bg-white dark:border-white rounded py-2 px-4 dark:hover:text-gray-900`}
            onClick={toggleDarkMode}
          >
            {darkMode ? 'Light Mode' : 'Dark Mode'}
          </button>
          {/* <button
            className="ml-4 text-white bg-transparent border border-white rounded py-2 px-4 hover:bg-white hover:text-gray-800"
            onClick={onLanguageToggle}
          >
            {language}
          </button> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
