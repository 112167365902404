import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../constants";
import api from "../../lib/axios";

// Create the async thunk
export const fetchSelectedColumns = createAsyncThunk(
  'selectedColumns/fetchSelectedColumns',
  async () => {
    try {
      // Perform the API request to fetch the selected columns
      const response = await api.get(`${BASE_URL}/leads/layout/`);
      const data = await response.data;

      return data; // Return the fetched selected columns
    } catch (error) {
      // Handle any errors during the API request
      throw new Error('Failed to fetch selected columns');
    }
  }
);

// Create the async thunk
export const updateSelectedColumns = createAsyncThunk(
  'selectedColumns/updateSelectedColumns',
  async (columns: string[], thunkAPI) => {
    try {
      let body = {columns : columns} 
      // Perform the API request to fetch the selected columns
      const response = await api.put(`${BASE_URL}/leads/layout/`, body);
      const data = await response.data;

      return data; // Return the fetched selected columns
    } catch (error) {
      // Handle any errors during the API request
      throw new Error('Failed to fetch selected columns');
    }
  }
);