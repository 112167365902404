import axios from 'axios';
import { LoginResponse } from '../types';
import { BASE_URL } from '../../../constants';

export type LoginCredential = {
  email: string;
  password: string;
};

export type refreshCredential = {
  refresh: string
}

export type passwordResetCredential = {
  email: string
}

export type passwordResetConfirmCredential = {
  new_password: string;
  uid: string;
  token: string;
}

export const loginWithEmailAndPassword = async (data: LoginCredential): Promise<LoginResponse> => {
  const response = await axios.post(`${BASE_URL}/accounts/auth/login/`, data);
  return response.data
};

export const getAccessToken = async (data: refreshCredential) => {
  const response = await axios.post(`${BASE_URL}/accounts/auth/refresh/`, data)
  return response.data
}

export const resetPassword = async (data: passwordResetCredential) => {
  const response = await axios.post(`${BASE_URL}/accounts/auth/reset-password/`, data)
  return response.data
}

export const resetPasswordConfirm = async (data: passwordResetConfirmCredential) => {
  const response = await axios.post(`${BASE_URL}/accounts/auth/reset-password-confirm/`, data)
  return response.data
}