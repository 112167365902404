import React from "react";

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps ) => {
  return(
    <main className="dark:bg-gray-900 h-screen">{children}</main>
  )
}