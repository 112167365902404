import { createSlice } from '@reduxjs/toolkit';
import { fetchSelectedColumns, updateSelectedColumns } from '../actions/leadSelectedColumnsAction';

// Create the slice
const selectedColumnsSlice = createSlice({
  name: 'selectedColumns',
  initialState: {
    selectedColumns: [],
    loading: false,
    error: null as string | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSelectedColumns.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSelectedColumns.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedColumns = action.payload;
      })
      .addCase(fetchSelectedColumns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateSelectedColumns.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSelectedColumns.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedColumns = action.payload;
      })
      .addCase(updateSelectedColumns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      });
  },
});

export default selectedColumnsSlice.reducer;
