import { Routes, Route } from "react-router-dom"
import Register from "../pages/Register/Register"
import Login from "../pages/Login/Login"
import NotFoundPage from "../pages/404Page/NotFoundPage"
import ForgotPasswordPage from "../pages/ForgotPassword/ForgotPasswordPage"
import ForgotPasswordConfirmPage from "../pages/ForgotPassword/ForgotPasswordConfirmPage"

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/password-reset" element={<ForgotPasswordPage />} />
      <Route path="/password-reset-confirm/:uuid/:token" element={<ForgotPasswordConfirmPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export const publicRoutes = [
  {
    path: '/auth/*',
    element: <AuthRoutes />
  }
]
