import React, { useEffect, useRef, useState } from 'react';
import { SearchableDropdownCustom } from '../Elements/Dropdown/SearchableDropdown';
import { fieldOption } from '../../features/auth/api/location/location';

interface OptionInt {
  value: string;
  label: string;
}

interface Props {
  // id: string;
  name_: string
  label: string;
  options: OptionInt[] | undefined;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface SelectProductProps {
  // id: string;
  name_: string
  label: string;
  index: number;
  options: {
    value: string;
    label: string;
  }[] | undefined;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export type Option = {
  value: string;
  label: string;
}

// type SearchableDropdownProps = {
//   // id: string;
//   name_: string
//   label: string;
//   index: number;
//   options: Option[] | undefined;
//   value: string;
//   onChange: (event: string, name_: string, index: number) => void;
// };
const SelectField: React.FC<Props> = ({ name_, label, options, value, onChange }) => (
  <div className="flex flex-col">
    <label className="font-medium dark:text-emerald-200">{label}</label>
    <select
      name={name_}
      value={value}
      onChange={onChange}
      className="border-2 bg-blue-50 dark:bg-slate-900 dark:text-slate-300 border-blue-200 flex-grow min-w-[280px] rounded-lg py-3 px-2.8 my-2"
    >
      {/* <option value="">Select an option</option> */}
      {options?.map(({label, value}) => (
        <option key={label?.toString()} value={value}>
          {label}
        </option>
      ))}
    </select>
  </div>
);

export const SelectFieldObject: React.FC<Props> = ({ name_, label, options, value, onChange }) => (
  <div className="flex flex-col">
    <label className="font-medium dark:text-emerald-200">{label}</label>
    <select
      name={name_}
      value={value}
      onChange={onChange}
      className="border-2 bg-blue-50 dark:bg-slate-900 dark:text-slate-300 border-blue-200 flex-grow min-w-[280px] rounded-lg py-3 px-2.8 my-2"
    >
      {options?.map(({label, value}) => (
        <option key={label?.toString()} value={value}>
          {label}
        </option>
      ))}
    </select>
  </div>
);

export const SelectProductField: React.FC<SelectProductProps> = ({ name_, label, index, options, value, onChange }) => (
  <div className="flex flex-col">
    <label className="font-medium dark:text-fuchsia-200">{label}</label>
    <select
      name={name_}
      data-index={index}
      value={value}
      onChange={onChange}
      className="bg-blue-50 dark:bg-slate-900 dark:text-slate-300 border-2 border-blue-200 rounded-lg min-w-[280px] py-3 px-3 my-2"
    >
      {/* <option value="">Select an option</option> */}
      {options?.map(({label, value}) => (
        <option key={label?.toString()} value={value}>
          {label}
        </option>
      ))}
    </select>
  </div>
);

type PropsDropdown = {
  value: string
  name_: string
  index: number
  label: string
  options: fieldOption[] | undefined
  handleProductName: (selectedOption: number, name_: string, index: number) => void;
  placeholder: string
}

export const SearchableDropdown: React.FC<PropsDropdown> = ({value, label, options, placeholder, name_, index, handleProductName }) => {
  
  const handleSelectedOption = (selectedOption: fieldOption) => {
    // Handle the selected option in the parent component
        const selectedValue = selectedOption.id;
        handleProductName(selectedValue, name_, index)
  };

  const defaultValue = options?.find((option) => option.id === Number(value))?.label

  return (
    <div className="flex flex-col">
      <label className="font-medium dark:text-fuchsia-200">{label}</label>
      <SearchableDropdownCustom
        options={options}
        placeholder={placeholder}
        defaultValue={defaultValue}
        handleSelectedOption={handleSelectedOption}
        // onChange={onChange}
      />
    </div>
  )
}

export const MultiSelectDropdown: React.FC<Props> = ({ name_, label, options, value, onChange }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleItemClick = (item: string) => {
    if(selectedItems.includes(item)){
      setSelectedItems(selectedItems.filter((selected) => selected !== item))
    } else {
      setSelectedItems([...selectedItems, item])
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.label);
    setSelectedItems(selectedOptions);
    onChange(event)
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={selectRef} className='relative min-w-[280px]'>
       <label
        className="font-medium dark:text-emerald-200 cursor-pointer"
        >
          {label}
        </label>
       <div 
        className="flex flex-wrap border border-gray-300 rounded p-1 my-2 h-11"
        onClick={() => setIsOpen(!isOpen)}  
      >
        {selectedItems.map((item) => (
          <div key={item} className="bg-gray-100 rounded px-2 py-1 m-1 flex items-center ">
            {item}
            <button
              className="ml-2 text-red-600 text-sm font-bold"
              onClick={() => handleItemClick(item)}
            >
              X
            </button>
          </div>
        ))}
      </div>
      {isOpen && (
        <select
          className="absolute top-full left-0 border-2 bg-blue-50 dark:bg-slate-900 dark:text-slate-300 border-blue-200 flex-grow min-w-[280px] rounded-lg py-3 px-2.8 my-2"
          multiple
          value={selectedItems}
          onChange={handleSelectChange}
        >
          {options?.map(({ label, value }, index) => (
            <option key={index} value={value}>
              {label}
            </option>
          ))}
        </select>
      )}
    </div>
  )
}

export default SelectField;


