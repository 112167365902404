import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Spinner } from '../components/Elements/Spinner';
import { MainLayout } from '../components/Layout/MainLayout';
import DashboardPage from '../pages/Dashboard/Dashboard';
import LeadCreateDetailPage from '../pages/Leads/LeadCreateDetail';
import LeadsPage from '../pages/Leads/Leads';
import Sidebar from '../components/SideNav/SideNavBar';
import Header from '../components/Header/Header';
import ProfilePage from '../pages/Profile/ProfilePage';
import TaskCreateDetail from '../pages/Tasks/TaskCreateDetail';
import Tasks from '../pages/Tasks/Tasks';

// import CustomForm from '../components/Form/CustomForm/CustomForm';
// const Register = lazy(() => import('../pages/Register/Register'));
// const id = 2

const App = () => {
  return (
    <MainLayout>
      <div className="bg-blue-100 dark:bg-gray-900">
        <Header />
        <div className="flex " >
          <Sidebar />
          <div className='overflow-x-auto w-full'>
          <Suspense
            fallback={
              <div className="h-full w-full flex items-center justify-center">
                <Spinner size="xl" />
              </div>
            }
          >
            <Outlet />
          </Suspense>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: 'dashboard', element: <DashboardPage /> },
      { path: 'profile', element: <ProfilePage /> },
      { path: 'leads/', element: <LeadsPage /> },
      { path: 'lead/:id/', element: <LeadCreateDetailPage /> },
      { path: 'create-lead/', element: <LeadCreateDetailPage /> },
      { path: 'tasks/', element: <Tasks /> },
      { path: 'create-task/', element: <TaskCreateDetail /> },
      { path: 'task/:id', element: <TaskCreateDetail /> },
      // {path: 'forms/', element: <CustomForm formId={id} /> },
      // { path: 'lead/:id/', element: <LeadDetailPage /> },
      // {path: 'create-lead/', element: <CreateLeadPage /> },
      // {path: 'create-product/', element: <LeadCreateDetailPage /> }
    ],
  },
];