import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { ProfileType, getProfile, updateProfileAPI } from '../../features/profile/api';
import { showErrorMessage, showMessage } from '../../components/Elements/Message';
import axios from 'axios';
import { ProfileForm } from '../../components/Form/Profile/ProfileForm';
import Loader from '../../components/Elements/Loader/Loader';
import { fields_profile } from '../../components/Form/Profile/constants';

const ProfilePage = () => {
  const [profileData, setProfileData] = useState<ProfileType | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = React.useState(false);
  const [titlePopup, setTitlePopup] = useState<string>("")
  const [errors, setErrors] = useState<{ [key: string]: string }>({});


  useEffect(() => {
    const fetchProfile = async () => {
          setLoading(true)
          try {
              const response = await getProfile();
              setProfileData(response[0]);
          } catch (error) {
              console.error(error)
              if (axios.isAxiosError(error)) {
                const apiError = error.response?.data;
                console.log("apiError type: ", typeof(apiError))
                console.log("apiError: ", apiError)
                showErrorMessage(apiError.errors)
                
                // Handle API error...
              } else {
                // Handle other types of errors...
              }
          } finally {
              setLoading(false)
          }
        
      };
      fetchProfile();
  }, [])

  const updateProfile = async (formData : FormData) => {
    setLoading(true)
    const avatar_form = formData.get("avatar")
    if (avatar_form && typeof avatar_form === 'string'){
      formData.delete("avatar")
    }
    try {
      if (formData){
          let response = await updateProfileAPI(formData);
          showMessage(response.message)
          setProfileData(response.data)
          // console.log(response.message)
          // navigate("/app/leads/")
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const apiError = error.response?.data;
        showErrorMessage(apiError.errors)
        
        // Handle API error...
      } else {
        // Handle other types of errors...
      }
    }  finally {
        setLoading(false)
        setIsOpen(false)
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // const validationErrors: { [key: string]: string } = {};

    const formData = new FormData();

    if (profileData) {
      fields_profile.map(({ name_ }) => {
        const value = profileData[name_];

        if (typeof value === 'string' || value instanceof File) {
          formData.append(name_, value);
        }
      });
    }

    let updateProfileFn = await updateProfile(formData)
    // if (!name) {
    //   validationErrors.name = 'Name is required';
    // }

    // if (!phone) {
    //   validationErrors.phone = 'Phone is required';
    // }

    // if (!address) {
    //   validationErrors.address = 'Address is required';
    // }

    // if (!role) {
    //   validationErrors.role = 'Role is required';
    // }

    // if (Object.keys(validationErrors).length === 0) {
    //   // Submit the form or perform any other desired action
    //   console.log('Form submitted');
    // } else {
    //   setErrors(validationErrors);
    // }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const files = e.target.files;
    if (files && files.length > 0) {
      setProfileData((prevState: ProfileType | null) => {
        return {
          ...(prevState ?? {}),
          [name]: files[0],
        } as ProfileType | null;
      })
    } else {
      setProfileData((prevState: ProfileType | null) => {
        return {
          ...(prevState ?? {}),
          [name]: value,
        } as ProfileType | null;
      })
    }
  };

  return (
    <div className=" container h-screen mx-auto px-4 pt-4 dark:bg-gray-900 rounded-lg">
      <h2 className="text-2xl mb-4 dark:text-slate-100">Profile</h2>
      {loading ? <Loader show={true} /> : 
      <ProfileForm 
      profile={profileData}
      handleSubmit={handleSubmit}
      onChange={onChange}
    />}
    </div>
  );
};

export default ProfilePage;
