import { BASE_URL } from "../../../constants";
import api from "../../../lib/axios";
import { Product } from "../../products/api";

export interface Assignee {
    id: number;
    name: string;
}

export type Lead = {
    [key: string]: string | undefined | number | boolean | { name: string} | Assignee[] | Product[];
    id: number;
    title?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    status?: string;
    source?: string;
    city?: string;
    state?: string;
    postal_code?: string;
    country?: string;
    website?: string;
    description?: string;
    created_at?: string;
    updated_at?: string;
    is_active?: boolean;
    industry?: string;
    close_date?: string;
    product?: Product[];
    created_by?: {
        name: string
    };
    updated_by?: {
        name: string
    };
assigned_to?: Assignee[]
  };

export const getLeads = async (currentPage: number) => {
    const response = await api.get(`${BASE_URL}/leads/leads/?page=${currentPage}`)
    return response.data
}

export const getLeadWithID = async (id: number) => {
    const response = await api.get(`${BASE_URL}/leads/${id}/`)
    return response.data
}

export const postLeadData = async (id: number, lead: Lead) => {
    const response = await api.put(`${BASE_URL}/leads/${id}/`, lead)
    return response.data
}

export const createLeadData = async (lead: Lead) => {
    const response = await api.post(`${BASE_URL}/leads/`, lead)
    return response.data
}

export const deleteLeadData = async (id: number) => {
    const response = await api.delete(`${BASE_URL}/leads/${id}/`)
    return response.data
}

// export async function handleLeadResponse (data: LeadResponse) {
//     const response = await getLeads()

// }