import React, { useEffect } from 'react'
import { Product } from '../../../features/products/api'

type Props = {
  product_arr: Product[]
}

const ProductDetails = ({product_arr}: Props) => {
  
  const product_a = product_arr ? product_arr : []

  useEffect(() => {
    console.log("product_a:", product_a)
  })
  
  return (
    <div className="flex flex-col gap-2 dark:bg-sky-800">
      {product_a.map((product: Product, index: number) => (
        <div className="dark:bg-cyan-950 m-1 rounded-xl p-3">
        <div className='dark:text-violet-300 py-5'>Product Information - {index + 1}</div>
        <div key={index} className="grid grid-cols-2 gap-4">
          {Object.entries(product).map(([productKey, productValue]) => (
              <div key={productKey}>
                <div className="text-sm font-medium dark:text-pink-300 mb-2">{productKey}</div>
                <div className="text-lg font-medium text-gray-900 dark:text-slate-300 min-h-[2rem]">{productValue?.toString()}</div>
                <hr className="my-2 border-cyan-200"/>
              </div>
            ))}
        </div>
        </div>
      ))}
    </div>
  )
}

export default ProductDetails