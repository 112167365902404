import React, { useEffect, useState } from 'react'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { fieldOption } from '../../../features/auth/api/location/location'

type Props = {
    options : fieldOption[] | undefined
    placeholder: string
    defaultValue?: string
    handleSelectedOption: (option: fieldOption) => void;
    // onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchableDropdownCustom = ({options, placeholder, defaultValue, handleSelectedOption}: Props) => {
    const [inputValue, setInputValue] = useState("")
    const [selectedOption, setSelectedOption] = useState(defaultValue || "")
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if(defaultValue){
            setSelectedOption(defaultValue)
        }
    }, [defaultValue])

    const handleOptionClick = (option: fieldOption) => {
        setSelectedOption(option.label);
        setOpen(false)
        setInputValue('');
      
        if (handleSelectedOption) {
          handleSelectedOption(option);
        }
      };

    return (
        <div className="w-72 font-medium">
            <div 
                className="flex justify-between bg-sky-50 dark:bg-slate-900 dark:text-slate-300 border-2 border-blue-200 rounded-lg min-w-[260px] py-3 px-3 my-2"
                onClick={() => setOpen(!open)}
            > 
                {selectedOption ? selectedOption : "Select an option"}
                <ChevronDownIcon className={`h-6 w-6 mx-4 ${open && "rotate-180"}`} />
            </div>
            <ul 
                className={`bg-white border ${open ? "border-cyan-200 border-2 " : "border-0"} overflow-y-auto max-w-[280px] rounded-lg ${open ? "max-h-60 absolute z-10" : "max-h-0"} `}
            >
                
                <div className="flex flex-row items-center px-2 sticky top-0 bg-sky-200 dark:bg-slate-700">
                    <MagnifyingGlassIcon className="h-4 w-4 m-2 dark:text-slate-300 " />
                    <input 
                        type="text" 
                        value={inputValue}
                        placeholder="Enter product name" 
                        className="max-w-[200px] bg-sky-200 dark:text-slate-300 placeholder:text-gray-700 p-2 outline-none dark:bg-slate-700"
                        onChange={(e) => setInputValue(e.target.value.toLowerCase())}
                    />
                </div>
                {options?.map((option: fieldOption) => (
                    <li 
                        key={option.id} 
                        className={`p-2 text-sm dark:bg-slate-800 border-1.5 border-gray-500 dark:text-slate-300 hover:bg-cyan-400 dark:hover:bg-cyan-300 dark:hover:text-gray-700
                            ${option.label.toLowerCase().startsWith(inputValue) ? "block" : "hidden"}`}
                        onClick={() => {
                            if(option.label.toLowerCase() !== selectedOption.toLowerCase()){
                                handleOptionClick(option)
                            }
                        }}
                    >
                        {option.label}
                    </li>
                ))}
            </ul>
        </div>
    );
};
