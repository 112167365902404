import { fetchLeadsRequest, fetchLeadsSuccess } from "../reducers/leadSlice";
import { AppThunk } from "../store/configureStore";
import api from "../../lib/axios";
import { BASE_URL } from "../../constants";

export const fetchLeads = (currentPage: number): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchLeadsRequest());

    const response = await api.get(`${BASE_URL}/leads/leads/?page=${currentPage}`);
    dispatch(fetchLeadsSuccess(response.data));
  } catch (error) {
    
    // dispatch(fetchLeadsFailure(error.message));
  }
};

export const filterLeads = (currentPage: number, body: string): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchLeadsRequest());
    let body_json = JSON.parse(body)
    const response = await api.post(`${BASE_URL}/leads/filter/?page=${currentPage}`, body_json);
    dispatch(fetchLeadsSuccess(response.data));
  } catch (error) {
    // dispatch(fetchLeadsFailure(error.message));
  }
};
