import { useEffect, useState } from "react";
import FilterAttribute from "./filterAttributes";
import { useDispatch } from "react-redux";
import { filterLeads } from "../../redux/actions/leadActions";
import { AppDispatch } from "../../redux/store/configureStore";
import { useNavigate } from "react-router-dom";

interface FilterComponentProps {
  isVisible: boolean;
  onClose: () => void;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  filterParams: string;
  setFilterParams: React.Dispatch<React.SetStateAction<string>>;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  isVisible,
  onClose,
  currentPage,
  setCurrentPage,
  filterParams,
  setFilterParams,
}) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [filterConditions, setFilterConditions] = useState<Record<string, any>>(
    {}
  );

  useEffect(() => {
    console.log("filterConditions", filterConditions);
  }, [filterConditions]);

  const convertToFilterGroup = (filters: any) => {
    const group_operator = "AND";
    const group = Object.values(filters);
    let filter_obj = { group_operator, group }
    setFilterParams(JSON.stringify(filter_obj))
    return filter_obj;
  };

  const handleApplyFilters = () => {
    Object.keys(filterConditions).forEach((key) => {
      if (filterConditions[key] === undefined) {
        delete filterConditions[key];
      }
    });

    const convertedFilter = convertToFilterGroup(filterConditions);

    let body_str = encodeURIComponent(JSON.stringify(convertedFilter))
    setCurrentPage(1)
    navigate(`/app/leads/?page=1&filter=${body_str}`)
    // dispatch(filterLeads(body_str));
  };

  useEffect(() => {
    if (filterParams){
      let group = JSON.parse(filterParams).group

      const result = {
        filterConditions: group.reduce((acc: any, item: any) => {
          acc[item.field_name] = item;
          return acc;
        }, {})
      };
      setFilterConditions(result.filterConditions)
      dispatch(filterLeads(currentPage, filterParams));
    }
  }, [dispatch, filterParams, currentPage])

  const handleCancel = () => {
    // Clear selectedAttributes and close the filter component
    onClose();
  };

  return (
    <div
      className={`fixed right-0 top-0 w-3/4 sm:w-1/4 h-screen overflow-y-auto bg-cyan-100 border-l border-dashed border-cyan-200 dark:bg-gray-900 shadow-xl shadow-cyan-200 transition-all duration-500 ${
        isVisible ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="flex items-center justify-between px-4 py-3 border-b border-dashed border-cyan-500">
        <h2 className="text-lg font-bold dark:text-gray-100 ">Filters</h2>
        <button
          onClick={handleCancel}
          className="text-gray-500 dark:text-gray-300 hover:text-gray-700 focus:outline-none"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="p-4">
        {/* Render the list of attributes with checkboxes */}
        <h3 className="text-lg font-bold mb-2 dark:text-gray-100">
          Attributes
        </h3>
        <FilterAttribute
          filterConditions={filterConditions}
          setFilterConditions={setFilterConditions}
        />
      </div>
      {/* Render the filter dropdowns for selected attributes */}
      {/* Add logic to handle comparator selection and value input */}
      <div className="flex items-center justify-end p-4 border-t border-dashed border-cyan-500">
        <button
          onClick={handleApplyFilters}
          className="px-4 py-2 mr-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          Apply Filters
        </button>
        <button
          onClick={handleCancel}
          className="px-4 py-2 text-gray-500 rounded-lg hover:text-gray-700"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default FilterComponent;
