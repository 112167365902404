import React, { useEffect, useState } from 'react'
import { Assignee, Task } from '../../../features/tasks';
import FormLayout from '../../Layout/FormLayout';
import { fieldTypeTask, fields_create } from './constants';
import SelectField, { SelectFieldObject } from '../SelectField';
import InputField, { DateInputField } from '../InputField';
import { updateFieldsCreate } from '../common/utils';
import { handleProfileOptions } from '../../../features/auth/api/accounts/profile';

interface Props {
  task: Task | null;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void;
  handleSaveClick: () => void;
  setProfileList: React.Dispatch<React.SetStateAction<Assignee[]>>
}

const TaskForm: React.FC<Props> = ({
  task,
  handleInputChange,
  handleSaveClick,
  setProfileList,
}) => {

  const [updatedFields, setUpdatedFields] = useState<fieldTypeTask[]>(fields_create)

  const fetchProfileList = async() => {
    try {
      let {transformedArray, assigneeArr} = await handleProfileOptions();
      console.log(transformedArray)
      updateFieldsCreate<fieldTypeTask>(transformedArray, "assigned_to", setUpdatedFields);
      setProfileList(assigneeArr)
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  }

  useEffect(() => {
    fetchProfileList();
  }, [])

  return (
    <FormLayout title={task?.title}>
      {updatedFields.map((
        { 
          name_,
          label,
          type,
          options,
          required 
        }
      ) => (
        <div key={name_} className='flex flex_col'>
          {type === 'select' ? (
            <SelectField 
              name_={name_ as string}
              label={label}
              options={options}
              value={task?.[name_]?.toString() ?? ''}
              onChange={handleInputChange}
            />
          ) : type === 'selectMulti' ? (
            <SelectFieldObject
              name_={name_ as string}
              label={label}
              options={options}
              value={(Array.isArray(task?.assigned_to) && task?.assigned_to[0]?.id) ? task?.assigned_to[0]?.id?.toString() : ''}
              onChange={handleInputChange}
            />
          ) : type === 'date' ? (
            <DateInputField
              name_={name_ as string}
              value={task?.[name_]?.toString() ?? ''}
              label={label}
              onChange={handleInputChange}
            />
          ) : (
            <InputField
              type={type}
              label={label}
              name_={name_ as string}
              placeholder={name_ as string}
              value={task?.[name_]?.toString() ?? ''}
              required={required}
              onChange={handleInputChange}
            />
          ) }
        </div>
      ))}
    </FormLayout>
  )
}

export default TaskForm