import React, { useEffect, useState } from 'react'
import { fields } from '../Form/Lead/constants'
import { fieldOption, handleBlockOptions, handleDistrictOptions, handleStateOptions } from '../../features/auth/api/location/location'

type Props = {
  filterConditions: Record<string, any>
  setFilterConditions: React.Dispatch<React.SetStateAction<Record<string, any>>>
}

const FilterAttribute = ({ filterConditions, setFilterConditions }: Props) => {
  const [updatedFields, setUpdatedFields] = useState(fields);
  // const [selectedDistrict, setSelectedDistrict] = useState("");

  const updateFieldsCreate = (responseData: fieldOption[], field_name: string) => {
    const updatedFieldsFn = updatedFields.map((field) => {
      if (field.type === "select" && field.name_ === field_name && field.options) {
        const existingOptions = {
          ...field,
          options: field.options.filter((option) => option.id === 0),
        };
        return { ...field, options: [
          ...existingOptions.options,
          ...responseData.map((option) => ({
            id: option.id,
            value: option.value,
            label: option.label
          }))
        ]}
      }
      return field;
    });

    setUpdatedFields(updatedFieldsFn)
  }

  useEffect(() => {
    // console.log("filterConditions", filterConditions)
  })
  
  useEffect(() => {
    const fetchStateOptions = async() => {
      const stateOptions = await handleStateOptions() //[{"id": 2, "value": "am", "label": "AM"}]  //
      updateFieldsCreate(stateOptions, "state")
    }
    fetchStateOptions();
  }, [])

  useEffect(() => {
    const fetchDistrictOptions = async() => {

      if(filterConditions?.state?.value) {
        let id = updatedFields
                  .filter(field => field.name_ === "state" && field.type === "select")
                  .map(field => (field.options || [])
                  .filter((option) => option.value === filterConditions.state.value)
                  .map((option) => option.id))

        console.log(filterConditions.state.value)

        if(id){
          let id_state = id[0][0]
          const districtOptions = await handleDistrictOptions(id_state)
          updateFieldsCreate(districtOptions, "district")
        }
      }
    }
    fetchDistrictOptions();
  }, [filterConditions?.state])

  useEffect(() => {
    const fetchBlockOptions = async() => {

      if(filterConditions?.district?.value) {
        let id = updatedFields
                  .filter(field => field.name_ === "district" && field.type === "select")
                  .map(field => (field.options || [])
                  .filter((option) => option.value === filterConditions.district.value)
                  .map((option) => option.id))

        if(id){
          let id_district = id[0][0]
          const blockOptions = await handleBlockOptions(id_district)
          updateFieldsCreate(blockOptions, "block")
        }
      }
    }
    fetchBlockOptions();
  }, [filterConditions?.district])



  const handleComparatorChange = (event: React.ChangeEvent<HTMLSelectElement>, attribute: string) => {
    const { value } = event.target
    setFilterConditions(prevState => ({
      ...prevState,
      [attribute]: {
        ...prevState[attribute],
        comparator: value
      }
    }))
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> , attribute: string) => {
    const { value } = event.target
    setFilterConditions(prevState => ({
      ...prevState,
      [attribute]: {
        ...prevState[attribute],
        value
      }
    }))
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, attribute: string) => {
    const { checked } = event.target
    setFilterConditions(prevState => ({
      ...prevState,
      [attribute]: checked ? {field_name: attribute, comparator: "equals"} : undefined
    }))
  }

  return (
    <div>
      {updatedFields.map((attribute) => (
        <div key={attribute.name_} className='my-4'>
          <label className='dark:text-gray-100'>
            <input 
              type='checkbox' 
              className='mr-2 dark:text-gray-100 rounded'
              checked={filterConditions[attribute.name_] && true}
              onChange={(event) => handleCheckboxChange(event, attribute.name_.toString())}
            ></input>
            {attribute.label}
          </label>
          {filterConditions[attribute.name_] && (
            <div className="ml-4 my-2">
              <select
                value={filterConditions[attribute.name_].comparator || ""}
                onChange={(event) => handleComparatorChange(event, attribute.name_.toString())}
                className='dark:bg-gray-700 dark:text-gray-200  px-3 py-1 m-1 rounded-lg'
              >
                <option value="equals">Equals</option>
                <option value="not_equals">Not Equals</option>
                <option value="contains">Contains</option>
                <option value="not_contains">Not Contains</option>
                <option value="starts_with">Starts With</option>
                <option value="ends_with">Ends With</option>
                <option value="is_blank">Is Blank</option>
                <option value="is_not_blank">Is not Blank</option>
              </select>
              {(filterConditions[attribute.name_].comparator !== "is_blank"
                && attribute.type !== "select") 
                && (filterConditions[attribute.name_].comparator !== "is_not_blank"
                && attribute.type !== "select") 
                && (
                <input
                  type="text"
                  placeholder='Query here'
                  value={filterConditions[attribute.name_].value || ""}
                  onChange={(event) => handleValueChange(event, attribute.name_.toString())}
                  className='dark:bg-gray-700 dark:text-gray-200 px-4 py-1 m-1 rounded-lg'
                />
              )}
              {(filterConditions[attribute.name_].comparator !== "is_blank"
                && attribute.type === "select") 
                && (filterConditions[attribute.name_].comparator !== "is_not_blank"
                && attribute.type === "select") 
                && (
                <select
                  name={attribute.name_.toString()}
                  value={filterConditions[attribute.name_].value || ""}
                  onChange={(event) => handleValueChange(event, attribute.name_.toString())}
                  className="dark:bg-gray-700 dark:text-gray-200 px-4 py-1 m-1 rounded-lg"
                >
                  {/* options */}
                  {attribute.options?.map(({label, value}) => (
                    <option key={label?.toString()} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default FilterAttribute
