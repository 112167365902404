import React, { useEffect } from "react";
import { ShieldExclamationIcon } from "@heroicons/react/24/outline";
import ProductDetails from "../../Form/Product/utils";
import { Product } from "../../../features/products/api";
import Loader from "../Loader/Loader";
interface ConfirmDataProps<T> {
    title: string;
    data: string | null;
    onCancel: () => void;
    onSave: () => void;
    loading: boolean;
}

const ConfirmData = <T extends {[key: string]: any}>({ title, data, onCancel, onSave, loading}: ConfirmDataProps<T>) => {

  const product_data = data ? JSON.parse(data) : []
  const entries = product_data ? Object.entries(product_data) : []
  const product_arr: Product[] = product_data?.product ? product_data?.product : []

  useEffect(() => {
    // console.log(product_data)
    // console.log("entries: ", entries)
  })
  
    const arr_layout = () => {
      return (
        <div className="">
         <div className="grid grid-cols-2 gap-4">
          {entries.map(([key, value]) => (
            <div key={key}> 
              {typeof value !== "object" ?
            (<div className="flex flex-col">
            <div className="text-sm font-medium text-gray-700 dark:text-green-200 mb-2">{key}</div>
            <div className="text-lg font-medium text-gray-900 dark:text-gray-300 min-h-[2rem]">{value?.toString()}</div>
            <hr className="my-2 border-cyan-200"/>
          </div>) : null  
            }
            </div> 
          ))}
        </div>
        
        <div >
          <ProductDetails 
            product_arr={product_arr}
          />
        </div>
        </div>
      )
    }

    if (loading) {
      return <Loader show={true} />
    }
    
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div className="inline-block align-bottom bg-white dark:bg-slate-600 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white dark:bg-slate-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start justify-center">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ShieldExclamationIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-200">{title}</h3>
                  <hr className="my-3 border-cyan-500" />
                  <div className="mt-8">
                    {arr_layout()}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-300 dark:bg-gray-850 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={onSave}
              >
                Confirm
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      )
}

export default ConfirmData