import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer from '../reducers/leadReducer';
import layoutReducer from '../reducers/selectedColumnSlice'
import taskReducer from "../reducers/tasks/taskReducer"
// Configure store
const store = configureStore({
  reducer: {
    tasks: taskReducer,
    leads: rootReducer,
    layout: layoutReducer
  }
});

export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
