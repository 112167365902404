import axios from "axios";
import { getBlockResponse, getDistrictResponse, getProductNameReponse, getStateResponse } from "./types";
import { BASE_URL } from "../../../../constants";
import api from "../../../../lib/axios";

export const getState = async (): Promise<getStateResponse[]> => {
  const response = await axios.get("https://v4-8-dot-cropwaybackend.el.r.appspot.com/api/state/1")
  return response.data
}

export type fieldOption = {
  id: number
  value: string,
  label: string
}

export const handleStateOptions = async () => {
  const response = await getState();
  const transformedArray: fieldOption[] = response.map((item) => ({
    id: item.id,
    value: item.state,
    label: item.state,
  }));

  return transformedArray
}

export const getDistrict = async (id: number): Promise<getDistrictResponse[]> => {
  const response = await axios.get(`https://v4-8-dot-cropwaybackend.el.r.appspot.com/api/district_census/${id}`)
  return response.data
}

export const handleDistrictOptions = async (id: number) => {
  const response = await getDistrict(id);
  const transformedArray: fieldOption[] = response.map((item) => ({
    id: item.id,
    value: item.district,
    label: item.district,
  }));

  return transformedArray
}

export const getBlock = async (id: number): Promise<getBlockResponse[]> => {
  const response = await axios.get(`https://v4-8-dot-cropwaybackend.el.r.appspot.com/api/block_census/${id}`)
  return response.data
}

export const handleBlockOptions = async (id: number) => {
  const response = await getBlock(id);
  const transformedArray: fieldOption[] = response.map((item) => ({
    id: item.id,
    value: item.block,
    label: item.block,
  }));

  return transformedArray
}

export const getProductName = async (): Promise<getProductNameReponse[]> => {
  const response = await api.get(`${BASE_URL}/products/productname`)
  return response.data
}

export const handleProductNameOptions = async () => {
  const response = await getProductName();
  const transformedArray: fieldOption[] = response.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  return transformedArray
}