import React, { useState } from 'react';

type TooltipProps = {
  text: string;
  disabled?: boolean;
  children: React.ReactNode;
};

const Tooltip = ({ text, disabled, children }: TooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    if(!disabled){
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="relative inline-block">
      <div
        className="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
        {showTooltip && (
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none">
          <div className="relative">
            <div className="bg-black text-white text-center py-2 px-4 rounded-md text-sm opacity-70">
              {text}
            </div>
            <div className="absolute w-2 h-2 bg-black dark:bg-gray transform rotate-45 -translate-y-1/2 left-1/2 bottom-0"></div>
          </div>
        </div>        
        )}
      </div>
    </div>
  );
};

export default Tooltip;
