import { BASE_URL } from "../../../../constants";
import api from "../../../../lib/axios";
// import { Assignee } from "../../../leads/api";
import { ProfileResponse } from "../../types";

export const getProfiles = async (): Promise<ProfileResponse[]> => {
  const response = await api.get(`${BASE_URL}/accounts/profiles/all/`);
  return response.data
};

type fieldOption = {
  id: number,
  value: string,
  label: string,
}

export type Assignee = {
  id: number,
  name: string
}

export const handleProfileOptions = async () => {
  const response = await getProfiles();
  const transformedArray: fieldOption[] = response.map((item) => ({
    id: item.id,
    value: (item.id).toString(),
    label: item.name,
  }));
  const assigneeArr: Assignee[] = response.map((item) => ({
    id: item.id,
    name: item.name
  }));

  return {transformedArray, assigneeArr}
}