import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Task, createTaskData, deleteTaskData, getTaskWithID, postTaskData } from '../../features/tasks';
import TaskForm from '../../components/Form/Task/TaskForm';
import Loader from '../../components/Elements/Loader/Loader';
import { showErrorMessage, showMessage } from '../../components/Elements/Message';
import axios from 'axios';
import ConfirmData from '../../components/Elements/Popup/ConfirmPopupFormGeneric';
import TaskDetailForm from '../../components/Form/Task/TaskDetailForm';
import { Assignee } from '../../features/auth/api/accounts/profile';

type Props = {}

const TaskCreateDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [task, setTask] = useState<Task | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [titlePopup, setTitlePopup] = useState<string>("")
  const [profileList, setProfileList] = useState<Assignee[]>([{id: 0, name: ""}])

  useEffect(() => {
    const fetchTask = async () => {
      if (id) {
        setLoading(true)
        try{
          const response = await getTaskWithID(parseInt(id))
          setTask(response.data);
        } catch (error) {
          console.error(error)
          if (axios.isAxiosError(error)) {
            const apiError = error.response?.data;
            console.log("apiError type: ", typeof(apiError))
            console.log("apiError: ", apiError)
            showErrorMessage(apiError.errors)
            
            // Handle API error...
          } else {
            // Handle other types of errors...
          }
        } finally {
          setLoading(false)
        }
      }
    };

    fetchTask();
  }, [id])

  const createTask = async (task: Task) => {
    setLoading(true)
    try {
      if (task){
        const response = await createTaskData(task)
        showMessage(response.message)
        setTask(null)
        return true
      } 
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const apiError = error.response?.data;
        console.log("apiError type: ", typeof(apiError))
        console.log("apiError: ", apiError)
        showErrorMessage(apiError.errors)
      } else {
        // Handle other types of errors...
      }
      return false   
    } finally {
      setLoading(false)
      setIsOpen(false)
    }
  };

  const updateTask = async () => {
    setLoading(true)
    try {
      if (task){
        const response = await postTaskData(task.id, task);
        showMessage(response.message)
        navigate("/app/tasks/")
      } 
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const apiError = error.response?.data;
        console.log("apiError type: ", typeof(apiError))
        console.log("apiError: ", apiError)
        showErrorMessage(apiError.errors)
      } else {
        // Handle other types of errors...
      }
    } finally {
      setLoading(false)
      setIsOpen(false)
    }
  };

  const deleteTask = async () => {
    setLoading(true)
    try {
      if (task){
          let response = await deleteTaskData(task.id);
          showMessage(response.message)
          navigate("/app/tasks/")
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const apiError = error.response?.data;
        console.log("apiError type: ", typeof(apiError))
        console.log("apiError: ", apiError)
        showErrorMessage(apiError.errors)
        
        // Handle API error...
      } else {
        // Handle other types of errors...
      }
    } finally {
        setLoading(false)
    }
  }

  const handleConfirmClick = async () => {
    // setLoading(true)
    if (id) {
        if (titlePopup === "Are you sure you want to update this task...!!!"){
            let updateTaskfn = await updateTask()
          } else {
            let deleteLeadfn = await deleteTask()
          }
    } else {  
    let new_lead = task && createTask(task)
    }
  }

  const handleInputChange = async(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    console.log("value", value)
    console.log("check: ", profileList)
    const assignee = [profileList.find((option) => option.id === parseInt(value))]
    
    setTask((prevState: Task | null) => {
      if (name === 'assigned_to') {
        return {
          ...(prevState ?? {}),
          [name]: assignee,
        } as Task | null
      } else {
        return {
          ...(prevState ?? {}),
          [name]: value,
        } as Task | null
      }
    })
  }

  const handleSaveClick = async () => {  
    setIsOpen(true)
    if (id){
        setTitlePopup("Are you sure you want to update this task...!!!")
    } else {
      setTitlePopup("Are you sure you want to create this task...!!!")
    }
  }

  const handleDeleteClick = async () => {
    setTitlePopup("Are you sure you want to delete this lead...!!!")
    setIsOpen(true)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  if (!id && loading) {
    return <Loader show={true} />
  }

  if (id){
    if (!task || loading) {
        return <Loader show={true} />
    }
  }

  return (
    <div className='flex flex-col sm:m-5'>
      <h1 className="text-5xl sm:text-9xl font-black mt-5 dark:text-teal-600">{id ? "Task Details" : "Create Task"}</h1>
      <div className="bg-blue-200 dark:bg-gray-700 rounded-lg shadow-lg">
        { id ? 
        <TaskDetailForm           
          task={task}
          handleInputChange={handleInputChange}
          setProfileList={setProfileList}
        />
          : <TaskForm 
          task={task}
          handleInputChange={handleInputChange}
          handleSaveClick={handleSaveClick}
          setProfileList={setProfileList}
        />}
      </div>
      <div className="flex flex-row justify-between mx-5 my-10">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleSaveClick}>
        Save
        </button>
        {id && <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={handleDeleteClick}>
        Delete
        </button>}
      </div>
      {isOpen && (
        <ConfirmData<Task>
          title = {titlePopup}
          data={JSON.stringify(task)} 
          onCancel={onCancel} 
          onSave={handleConfirmClick} 
          loading={loading}
        />
      )}
    </div>
  )
}

export default TaskCreateDetail