import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Lead } from '../../features/leads/api';

interface LeadsState {
  data: Lead[]; // Adjust the type according to your API response structure
  loading: boolean;
  error: string | null;
}

const initialState: LeadsState = {
  data: [],
  loading: false,
  error: null
};

export const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    fetchLeadsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchLeadsSuccess: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchLeadsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchLeadsRequest,
  fetchLeadsSuccess,
  fetchLeadsFailure,
} = leadsSlice.actions;

export default leadsSlice.reducer;

