import React, { createContext, useEffect, useState } from 'react';
import storage from '../utils/storage';

interface AuthContextProps {
  username: string;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  logindetail: string;
  setLoginDetail: React.Dispatch<React.SetStateAction<string>>;
  auth: boolean;
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [username, setUsername] = useState('');
  const [logindetail, setLoginDetail] = useState('');
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    const tokenString = storage.getToken();
    const tokens = tokenString ? JSON.parse(tokenString) : null;
    const first_name = localStorage.getItem("first_name")
    const isAccess = tokens ? tokens.access : null
    const auth = Boolean(isAccess);

    if (first_name){
      setUsername(first_name)
    }

    if(auth){
      setAuth(auth)
    }
  }, [username, auth])

  const authContextValue: AuthContextProps = {
    username,
    setUsername,
    logindetail,
    setLoginDetail,
    auth,
    setAuth,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
