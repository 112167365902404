import axios from 'axios';
import { BASE_URL } from '../constants';
import storage from '../utils/storage';
import { getAccessTokenFn } from './auth';

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

api.interceptors.request.use((config) => {
    const tokenString = storage.getToken();
    const token = tokenString ? JSON.parse(tokenString) : null;
    if (token) {
        config.headers.Authorization = `Bearer ${token.access}`;
    }
    return config;
    });

    // Add a response interceptor to handle expired access tokens
api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
  
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        const token = JSON.parse(storage?.getToken()?.toString() ?? "");
        console.log("token: ", token)
        const refreshToken = token.refresh
        if (!refreshToken) {
          // If no refresh token is available, redirect to the login page
          // window.location.href = '/login';
          return Promise.reject(error);
        }
  
        try {
          let params = {
            "refresh": refreshToken
          }
          const tokenPairs = await getAccessTokenFn(params)
          storage.setToken(tokenPairs);
  
          // Retry the original request with the new access token
          const config = { ...originalRequest };
          config.headers.Authorization = `Bearer ${JSON.parse(tokenPairs).access}`;
          return axios(config);
        } catch (error) {
          // If the refresh token is invalid or expired, redirect to the login page
          // window.location.href = '/login';
          console.log("Error: ", error);
          return Promise.reject(error);
        }
      }
  
      return Promise.reject(error);
    }
  );
  
  export default api;
