import axios from 'axios';

import { LoginResponse, RegisterResponse } from '../types';
import { BASE_URL } from '../../../constants';

export type RegisterCredentials = {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  phone: string;
};

export const registerWithEmailAndPassword = (
  data: RegisterCredentials
): Promise<RegisterResponse> => {
  return axios.post(`${BASE_URL}/accounts/auth/register/`, data);
};