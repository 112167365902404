export const availableColumns = [
  { id: 'title', label: 'Lead Title' },
  { id: 'status', label: 'Status' },
  { id: 'priority', label: 'Priority' },
  { id: 'due_date', label: 'Due date' },
  { id: 'description', label: 'Description' },
  { id: 'created_by', label: 'Created By' },
  { id: 'updated_by', label: 'Updated By' },
  { id: 'assigned_to', label: 'Assigned To' },
];

export const defaultSelectedColumns = [
  'title', 'status', 'priority', 'description', 
]
