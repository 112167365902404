// LeadsHeader.js
import React from 'react';
import FilterRenderer from '../Filter/filterRenderer';

interface Props {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  filterParams: string;
  setFilterParams: React.Dispatch<React.SetStateAction<string>>
  handleCreate: () => void;
  handleShowColumnMenu: () => void;
}

const PageHeader = ({ 
  currentPage, 
  setCurrentPage, 
  filterParams, 
  setFilterParams, 
  handleCreate, 
  handleShowColumnMenu 
} : Props) => {
  return (
    <>
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-4 py-3 sm:px-6">
        <h2 className="text-2xl leading-6 font-semibold text-gray-900 dark:text-slate-100 py-2">All Leads</h2>
        <div className="flex justify-end">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-sm text-white md:py-2 px-2 md:px-4 mx-2 md:mx-4 rounded"
            onClick={handleShowColumnMenu}
          >
            Manage Column
          </button>

          <FilterRenderer
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />

          <button
            className="bg-blue-500 hover:bg-blue-700 text-sm text-white py-2 px-4 mx-2 md:mx-4 rounded"
            onClick={handleCreate}
          >
            Create Lead
          </button>
        </div>
      </div>

      <hr className="h-px mb-8 mt-2 bg-gray-200 border-0 dark:bg-gray-600" />
      
    </>
  );
};

export default PageHeader;
