import { useEffect, useState } from "react";
import FilterComponent from "./filterLead";

interface Props {
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  filterParams: string  
  setFilterParams: React.Dispatch<React.SetStateAction<string>>;
}

const FilterRenderer: React.FC<Props> = ({
  currentPage,
  setCurrentPage,
  filterParams,
  setFilterParams
}) => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggleFilter = () => {
    setIsFilterVisible((prev) => !prev);
  };

  const handleFilterClose = () => {
    setIsFilterVisible(false);
  };

  useEffect(() => {
      // console.log("filterParams", filterParams)
  })

  return (
    <div className="">
      <button 
        className="bg-blue-500 hover:bg-blue-700 text-sm text-white p-5 md:py-2 px-2 md:px-4 mx-2 md:mx-4 rounded"
        onClick={toggleFilter}
        >
          Filter
      </button>
      <FilterComponent 
        isVisible={isFilterVisible} 
        onClose={handleFilterClose} 
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        filterParams={filterParams}
        setFilterParams={setFilterParams}  
      />
    </div>
  );
};

export default FilterRenderer;
