import React from "react";
import { Product } from "../../../features/products/api";
import AddProductForm from "./AddProductForm";
import { Option } from "../SelectField";

type Props = {
  products: Product[] 
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleAddProduct: (product: Product) => void;
  handleDeleteProduct: (index: number) => void;
  handleProductName: (selectedOption: number, name_: string, index: number) => void;
  onSubmit: () => void;
};

const ProductEntryForm = ({
  products,
  handleInputChange,
  handleAddProduct,
  handleDeleteProduct,
  handleProductName,
}: Props) => {

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // onSubmit(products);
    console.log("Products: ", products)
  };

  return (
    <form onSubmit={handleSubmit}>
      {products.map((product, index) => (
        <div key={index} className="m-5">
          <AddProductForm 
              index={index} 
              product={product} 
              handleInputChange={handleInputChange} 
              handleDelete={handleDeleteProduct} 
              handleProductName={handleProductName}
        />
        </div>
      ))}
      <div>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-5 rounded" type="button" onClick={() => handleAddProduct({ category: ""})}>Add Product</button>
      </div>
    </form>
  );
};

export default ProductEntryForm;
