import React, { createContext, useState, useEffect } from 'react';

// Create the ThemeContext
const ThemeContext = createContext();

// Create a ThemeProvider component
const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    // Retrieve the theme preference from local storage
    const preferredTheme = localStorage.getItem('theme');
    if (preferredTheme) {
      setDarkMode(preferredTheme === 'dark');
    } else {
      // Check the user's preferred color scheme
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setDarkMode(prefersDarkMode);
    }
  }, []);

  useEffect(() => {
    // Store the theme preference in local storage
    localStorage.setItem('theme', darkMode ? 'dark' : 'light');
    // Update the class on the HTML element to apply the appropriate styles
    document.documentElement.classList.toggle('dark', darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    console.log("Theme: ", darkMode)
    setDarkMode(!darkMode);
  };

  // Provide the theme and toggle function to the children components
  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
