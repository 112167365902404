export function showErrorMessage(errors: any) {
  
  let errorMessage = '';

  console.log("errorMessageErrors", errors);

  if (typeof errors === 'string') {
    errorMessage = errors;
  } else if (Array.isArray(errors)) {
    console.log("errorMessage", errorMessage);
    errorMessage = errors.join('\n');
  } else if (typeof errors === 'object') {
    for (const field in errors) {
      if (errors.hasOwnProperty(field)) {
        if (Array.isArray(errors[field as keyof typeof errors])){
          errorMessage += `${field}: ${(errors[field as keyof typeof errors] as string[]).join(', ')}\n`;  
        } else {
          // console.log("error key: ", errors[field as keyof typeof errors])
          errorMessage += `${field}: ${(errors[field as keyof typeof errors] as string[])}\n`;}
      }
    }
    console.log("errorMessage after logic", errorMessage)
  }
  
  const popup = document.createElement('div');
  popup.classList.add('fixed', 'top-0', 'left-0', 'h-screen', 'w-screen', 'flex', 'items-center', 'justify-center', 'bg-black', 'bg-opacity-75', 'z-50');
  
  const popupContent = document.createElement('div');
  popupContent.classList.add('bg-white', 'p-4', 'rounded', 'shadow-lg');
  
  const messageElement = document.createElement('p');
  messageElement.textContent = errorMessage;
  messageElement.classList.add('text-red-500', 'text-lg', 'font-semibold', 'mb-4');
  
  const closeButton = document.createElement('button');
  closeButton.textContent = 'Close';
  closeButton.classList.add('bg-red-500', 'text-white', 'px-4', 'py-2', 'rounded', 'font-semibold');
  
  closeButton.addEventListener('click', () => {
    document.body.removeChild(popup);
  });
  
  popupContent.appendChild(messageElement);
  popupContent.appendChild(closeButton);
  popup.appendChild(popupContent);
  
  document.body.appendChild(popup);
}

export function showMessage(message: any) {
  
  let messageString = '';

  if (typeof message === 'string') {
    messageString = message;
  } else if (Array.isArray(message)) {
    messageString = message.join('\n');
  } else if (typeof message === 'object') {
    for (const field in message) {
      if (message.hasOwnProperty(field)) {
        messageString += `${field}: ${(message[field as keyof typeof message] as string[]).join(', ')}\n`;
      }
    }
  }
  
  const popup = document.createElement('div');
  popup.classList.add('fixed', 'top-0', 'left-0', 'h-screen', 'w-screen', 'flex', 'items-center', 'justify-center', 'bg-black', 'bg-opacity-75', 'z-50');
  
  const popupContent = document.createElement('div');
  popupContent.classList.add('bg-white', 'p-4', 'rounded', 'shadow-lg');
  
  const messageElement = document.createElement('p');
  messageElement.textContent = messageString;
  messageElement.classList.add('text-green-500', 'text-lg', 'font-semibold', 'mb-4');
  
  const closeButton = document.createElement('button');
  closeButton.textContent = 'Close';
  closeButton.classList.add('bg-emerald-500', 'text-white', 'px-4', 'py-2', 'rounded', 'font-semibold');
  
  closeButton.addEventListener('click', () => {
    document.body.removeChild(popup);
  });
  
  popupContent.appendChild(messageElement);
  popupContent.appendChild(closeButton);
  popup.appendChild(popupContent);
  
  document.body.appendChild(popup);
}
