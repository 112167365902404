import { useContext, useState } from 'react';
import { XMarkIcon, Bars3Icon, SunIcon, MoonIcon  } from '@heroicons/react/24/outline';
import { SunIcon as SunIconSolid, MoonIcon as MoonIconSolid  } from '@heroicons/react/24/solid';
import { AuthContext } from '../../providers/AuthContext';
import { SidebarLinks } from './LinksArr';
import { ThemeContext } from '../Theme/themeContext';
import Tooltip from '../Elements/Tooltip/Tooltip';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { darkMode, toggleDarkMode } = useContext(ThemeContext)
  const authContext = useContext(AuthContext)

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex h-screen dark:bg-gray-700">
      {/* Sidebar */}
      <div
        className={`flex flex-col ${isOpen ? "w-48" : "w-20"} duration-300 bg-sky-100 shadow-2xl shadow-black dark:shadow-cyan-200 dark:bg-gray-900 border-r border-gray-200 `}
      >
        {/* Sidebar header */}
        <div className="flex items-center p-4 justify-end">
          <h1 className={`text-xl font-semibold origin-left dark:text-gray-300 duration-300 ${!isOpen && "scale-0"}`}>
            {authContext ? `Hi, ${authContext.username}` : "Hi User"}
          </h1>
          <button
            className=" px-2 text-gray-600 focus:outline-none hover:text-gray-800"
            onClick={toggleSidebar}
          >
            {isOpen ? (
              <XMarkIcon className="w-6 h-6 dark:text-slate-200" />
            ) : (
              <Bars3Icon className="w-6 h-6 dark:text-slate-200" />
            )}
          </button>
        </div>

        {/* Sidebar links */}
        <SidebarLinks isOpen={isOpen} />

        {isOpen ? (
          <div className='flex justify-between items-center p-4'>
          {darkMode ? (
            <>
              <Tooltip text='Switch to Light mode'>
                <button onClick={toggleDarkMode}>
                  <SunIcon className={`w-6 h-6 mx-6 text-slate-300`} />
                </button >
              </Tooltip>
              <MoonIconSolid className={`w-6 h-6 mx-6 ${darkMode && "text-slate-100"}`} />
            </>
          ) : (
            <>
              <SunIconSolid className={`w-6 h-6 mx-6 ${!darkMode && "text-orange-400"}`} />
              <Tooltip text="Switch to Dark mode">
                <button onClick={toggleDarkMode}>
                  <MoonIconSolid className={`w-6 h-6 mx-6 text-black`} />
                </button>
              </Tooltip>
            </>
          )}
        </div>
        ) : (
          <Tooltip text='Switch theme'>
            <button className='m-1' onClick={toggleDarkMode}>
              {darkMode ? 
                <SunIcon className={`w-6 h-6 mx-6 text-slate-300`}/> 
                : 
                <MoonIcon className={`w-6 h-6 mx-6 text-black`} />}
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
