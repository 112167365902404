import Cookies from 'js-cookie';

const storagePrefix = 'crm_app';

const storage = {
  getToken: () => {
    const token = Cookies.get(`${storagePrefix}token`);
    return token || null;
  },
  setToken: (token: string) => {
    Cookies.set(`${storagePrefix}token`, token, { secure: true, sameSite: 'strict' });
  },
  clearToken: () => {
    Cookies.remove(`${storagePrefix}token`);
  },
};

export default storage;
