import { Lead } from "../../../features/leads/api";

export type fieldTypeLead = {
  name_: keyof Lead; 
  label: string; 
  type: string, 
  options?: {
    id: number;
    value: string;
    label: string;
  }[],
  required?: boolean;
}

export const fields: fieldTypeLead[] = [
  {
    name_: 'title',
    label: 'Lead Name',
    type: 'text',
    required: true
  },
  {
    name_: 'first_name',
    label: 'First Name',
    type: 'text',
  },
  {
    name_: 'last_name',
    label: 'Last Name',
    type: 'text',
  },
  {
    name_: 'description',
    label: 'Description',
    type: 'text',
  },
  {
    name_: 'industry',
    label: 'Industry',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'ASP (Application Service Provider)', label: 'ASP (Application Service Provider)' },
      { id: 2, value: 'Data/Telecom OEM', label: 'Data/Telecom OEM' },
      { id: 3, value: 'ERP', label: 'ERP' },
      { id: 4, value: 'other', label: 'Other' },
    ],
  },
  {
    name_: 'type_1',
    label: 'Type',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'buyer', label: 'Buyer' },
      { id: 2, value: 'seller', label: 'Seller' },
      { id: 3, value: 'other', label: 'Other' },
    ],
  },
  {
    name_: 'subtype_1',
    label: 'Subtype',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'farmer', label: 'Farmer' },
      { id: 2, value: 'fpo', label: 'FPO' },
      { id: 3, value: 'shg', label: 'SHG' },
      { id: 4, value: 'importer', label: 'Importer' },
      { id: 5, value: 'exporter', label: 'Exporter' },
      { id: 6, value: 'agent', label: 'Agent' },
      { id: 7, value: 'processor', label: 'Processor' },
      { id: 8, value: 'other', label: 'Other' },
    ],
  },
  {
    name_: 'email',
    label: 'Email',
    type: 'email',
  },
  {
    name_: 'phone',
    label: 'Phone',
    type: 'tel',
  },
  {
    name_: 'source',
    label: 'Source',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'call', label: 'Call' },
      { id: 2, value: 'email', label: 'Email' },
      { id: 3, value: 'campaign', label: 'Campaign' },
      { id: 4, value: 'other', label: 'Other' },
    ],
  },
  {
    name_: 'country',
    label: 'Country',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'India', label: 'India' },
      // { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
      // { value: 'Orissa', label: 'Orissa' },
      // { value: 'Rajasthan', label: 'Rajasthan' },
    ],
  },
  {
    name_: 'state',
    label: 'State',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
    ],
  },
  {
    name_: 'district',
    label: 'District',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
    ],
  },
  {
    name_: 'block',
    label: 'Block',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
    ],
  },
  {
    name_: 'city',
    label: 'City',
    type: 'text',
  },
  {
    name_: 'postal_code',
    label: 'Postal Code',
    type: 'number',
  },
  {
    name_: 'website',
    label: 'Website',
    type: 'text',
  },
  {
    name_: 'rating',
    label: 'Rating',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'acquired', label: 'Acquired' },
      { id: 2, value: 'active', label: 'Active' },
      { id: 3, value: 'market_failed', label: 'Market Failed' },
      { id: 4, value: 'project_cancelled', label: 'Project Cancelled' },
      { id: 5, value: 'shutdown', label: 'Shutdown' },
    ],
  },
  {
    name_: 'assigned_to',
    label: 'Assigned To',
    type: 'selectMulti',
    options: [
      { id: 0, value: '', label: 'Select an option' },
    ],
  },
  {
    name_: 'source_date',
    label: 'Source Date',
    type: 'date',
  },
  {
    name_: 'created_by',
    label: 'Created By',
    type: 'placeholder',
  },
  {
    name_: 'updated_by',
    label: 'Updated By',
    type: 'placeholder',
  },
];

export const fields_create: fieldTypeLead[] = [
  {
    name_: 'title',
    label: 'Lead Name',
    type: 'text',
    required: true
  },
  {
    name_: 'first_name',
    label: 'First Name',
    type: 'text',
  },
  {
    name_: 'last_name',
    label: 'Last Name',
    type: 'text',
  },
  {
    name_: 'description',
    label: 'Description',
    type: 'text',
  },
  {
    name_: 'industry',
    label: 'Industry',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'ASP (Application Service Provider)', label: 'ASP (Application Service Provider)' },
      { id: 2, value: 'Data/Telecom OEM', label: 'Data/Telecom OEM' },
      { id: 3, value: 'ERP', label: 'ERP' },
      { id: 4, value: 'other', label: 'Other' },
    ],
  },
  {
    name_: 'type_1',
    label: 'Type',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'buyer', label: 'Buyer' },
      { id: 2, value: 'seller', label: 'Seller' },
      { id: 3, value: 'other', label: 'Other' },
    ],
  },
  {
    name_: 'subtype_1',
    label: 'Subtype',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'farmer', label: 'Farmer' },
      { id: 2, value: 'fpo', label: 'FPO' },
      { id: 3, value: 'shg', label: 'SHG' },
      { id: 4, value: 'importer', label: 'Importer' },
      { id: 5, value: 'exporter', label: 'Exporter' },
      { id: 6, value: 'agent', label: 'Agent' },
      { id: 7, value: 'processor', label: 'Processor' },
      { id: 8, value: 'other', label: 'Other' },
    ],
  },
  {
    name_: 'email',
    label: 'Email',
    type: 'email',
  },
  {
    name_: 'phone',
    label: 'Phone',
    type: 'tel',
  },
  {
    name_: 'source',
    label: 'Source',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'call', label: 'Call' },
      { id: 2, value: 'email', label: 'Email' },
      { id: 3, value: 'campaign', label: 'Campaign' },
      { id: 4, value: 'other', label: 'Other' },
    ],
  },
  {
    name_: 'country',
    label: 'Country',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'India', label: 'India' },
      // { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
      // { value: 'Orissa', label: 'Orissa' },
      // { value: 'Rajasthan', label: 'Rajasthan' },
    ],
  },
  {
    name_: 'state',
    label: 'State',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
    ],
  },
  {
    name_: 'district',
    label: 'District',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
    ],
  },
  {
    name_: 'block',
    label: 'Block',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
    ],
  },
  {
    name_: 'city',
    label: 'City',
    type: 'text',
  },
  {
    name_: 'postal_code',
    label: 'Postal Code',
    type: 'number',
  },
  {
    name_: 'website',
    label: 'Website',
    type: 'text',
  },
  {
    name_: 'rating',
    label: 'Rating',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'acquired', label: 'Acquired' },
      { id: 2, value: 'active', label: 'Active' },
      { id: 3, value: 'market_failed', label: 'Market Failed' },
      { id: 4, value: 'project_cancelled', label: 'Project Cancelled' },
      { id: 5, value: 'shutdown', label: 'Shutdown' },
    ],
  },
  {
    name_: 'assigned_to',
    label: 'Assign To',
    type: 'selectMulti',
    options: [
      { id: 0, value: '', label: 'Select an option' },
    ],
  },
  {
    name_: 'source_date',
    label: 'Source Date',
    type: 'date',
  },
];
