import React, { useEffect, useState } from "react";
import { Assignee, Lead } from "../../../features/leads/api";
import { fieldTypeLead, fields_create } from "./constants";
import SelectField, { SelectFieldObject } from "../SelectField";
import InputField, { DateInputField } from "../InputField";
import { handleBlockOptions, handleDistrictOptions, handleStateOptions } from "../../../features/auth/api/location/location";
import { handleProfileOptions } from "../../../features/auth/api/accounts/profile";
import FormLayout from "../../Layout/FormLayout";
import { updateFieldsCreate } from "../common/utils";

interface LeadFormProps {
  lead: Lead | null;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void;
  handleSaveClick: () => void;
  setProfileList: React.Dispatch<React.SetStateAction<Assignee[]>>
}

const LeadForm: React.FC<LeadFormProps> = ({ 
  lead, 
  handleInputChange, 
  handleSaveClick,
  setProfileList,
}) => {
  const [updatedFields, setUpdatedFields] = useState<fieldTypeLead[]>(fields_create);

  const fetchProfileList = async() => {
    try {
      let {transformedArray, assigneeArr} = await handleProfileOptions();
      console.log(transformedArray)
      updateFieldsCreate<fieldTypeLead>(transformedArray, "assigned_to", setUpdatedFields);
      setProfileList(assigneeArr)
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  }

  useEffect(() => {
    const fetchStateOptions = async() => {
      const stateOptions = await handleStateOptions() //[{"id": 2, "value": "am", "label": "AM"}]  //
      updateFieldsCreate<fieldTypeLead>(stateOptions, "state", setUpdatedFields)
    }
    fetchStateOptions();
    fetchProfileList();
  }, [])

  useEffect(() => {
    const fetchDistrictOptions = async() => {

      if(lead?.state) {
        let id = updatedFields
                  .filter(field => field.name_ === "state" && field.type === "select")
                  .map(field => (field.options || [])
                  .filter((option) => option.value === lead.state)
                  .map((option) => option.id))

        if(id){
          let id_state = id[0][0]
          const districtOptions = await handleDistrictOptions(id_state)
          updateFieldsCreate<fieldTypeLead>(districtOptions, "district", setUpdatedFields)
        }
      }
    }
    fetchDistrictOptions();
  }, [lead?.state])

  useEffect(() => {
    const fetchBlockOptions = async() => {

      if(lead?.district) {
        let id = updatedFields
                  .filter(field => field.name_ === "district" && field.type === "select")
                  .map(field => (field.options || [])
                  .filter((option) => option.value === lead.district)
                  .map((option) => option.id))

        if(id){
          let id_district = id[0][0]
          const blockOptions = await handleBlockOptions(id_district)
          updateFieldsCreate<fieldTypeLead>(blockOptions, "block", setUpdatedFields)
        }
      }
    }
    fetchBlockOptions();
  }, [lead?.district])

  return (
    <FormLayout title={lead?.title}>
      {updatedFields.map(({ name_, label, type, options, required }) => (
        <div key={name_} className="flex flex-col">
          {type === 'select' ? (
            <SelectField
              name_={name_ as string}
              label={label}
              options={options}
              value={lead?.[name_]?.toString() ?? ''}
              onChange={handleInputChange}
            />
          ) : type === 'selectMulti' ? (
            <SelectFieldObject
              name_={name_ as string}
              label={label}
              options={options}
              value={(Array.isArray(lead?.assigned_to) && lead?.assigned_to[0]?.id) ? lead?.assigned_to[0]?.id?.toString() : ''}
              onChange={handleInputChange}
            />
          ) : type === 'date' ? (
            <DateInputField
              name_={name_ as string}
              value={lead?.[name_]?.toString() ?? ''}
              label={label}
              onChange={handleInputChange}
            />
          ) : (
            <InputField
              type={type}
              label={label}
              name_={name_ as string}
              placeholder={name_ as string}
              value={lead?.[name_]?.toString() ?? ''}
              required={required}
              onChange={handleInputChange}
            />
          )}
        </div>
      ))}
    </FormLayout>
  )
}

export default LeadForm;