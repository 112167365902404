export const availableColumns = [
  { id: 'title', label: 'Lead Title' },
  { id: 'first_name', label: 'First Name' },
  { id: 'last_name', label: 'Last Name' },
  { id: 'description', label: 'Description' },
  { id: 'email', label: 'Email' },
  { id: 'phone', label: 'Phone' },
  { id: 'type_1', label: 'Type 1' },
  { id: 'subtype_1', label: 'Subtype 1' },
  { id: 'status', label: 'Status' },
  { id: 'source', label: 'Source' },
  { id: 'industry', label: 'Industry' },
  { id: 'city', label: 'City' },
  { id: 'block', label: 'Block' },
  { id: 'district', label: 'District' },
  { id: 'state', label: 'State' },
  { id: 'rating', label: 'Rating' },
  { id: 'created_by', label: 'Created By' },
  { id: 'updated_by', label: 'Updated By' },
  { id: 'assigned_to', label: 'Assigned To' },
];

export const defaultSelectedColumns = [
  'title', 'first_name', 'last_name', 'email', 'phone'
]
