import { LoginCredential, getAccessToken, loginWithEmailAndPassword, passwordResetConfirmCredential, passwordResetCredential, refreshCredential, resetPassword, resetPasswordConfirm } from "../features/auth/api/login";
import { LoginResponse } from "../features/auth/types";
import storage from "../utils/storage";

async function handleLoginResponse(data: LoginResponse) {
    const { tokens } = data;
    console.log("tokens", tokens)
    let token_string = JSON.stringify(tokens)
    storage.setToken(token_string);
    localStorage.setItem("first_name", tokens.first_name)
    return tokens.first_name;
  }

export async function LoginFn (data: LoginCredential) {
  const response = await loginWithEmailAndPassword(data)
  const user = await handleLoginResponse(response)
  return user
}

export async function getAccessTokenFn (data: refreshCredential) {
  const response = await getAccessToken(data)
  let token_pair_string = JSON.stringify(response.tokens)
  return token_pair_string
}

export async function logoutFn() {
  storage.clearToken();
}

export async function resetPasswordFn (data: passwordResetCredential) {
  const response = await resetPassword(data)
  return response
}

export async function resetPasswordConfirmFn (data: passwordResetConfirmCredential) {
  const response = await resetPasswordConfirm(data)
  return response
}


