import { BASE_URL } from "../../../constants";
import api from "../../../lib/axios";

export type AddressType = {
  id: number;
  line_1: string;
  line_2: string;
  city: string;
  state: string;
  postal_code: number
  country: string
}

export type ProfileType = {
  [key: string] : string | File | AddressType | number | undefined
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  alt_phone: string;
  role: string;
  avatar?: string | File;
  address: AddressType
}

export const getProfile = async () => {
  const response = await api.get(`${BASE_URL}/accounts/profile/`)
  return response.data
}

export const updateProfileAPI = async (formData: FormData) => {
  const response = await api.put(`${BASE_URL}/accounts/profile/update/`, formData)
  return response.data
}