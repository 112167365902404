// import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
// import { Lead } from '../../features/leads/api';

// interface LeadState {
//   leads: Lead[];
//   isLoading: boolean;
//   totalRecords: number;
//   totalPages: number;
//   firstRecord: number;
//   lastRecord: number;
// }

// const initialState: LeadState = {
//   leads: [],
//   isLoading: false,
//   totalRecords: 0,
//   totalPages: 0,
//   firstRecord: 0,
//   lastRecord: 0
// };

// const leadSlice = createSlice({
//   name: 'leads',
//   initialState,
//   reducers: {
//     setLeads: (state, action: PayloadAction<{
//       total_records: number;
//       total_pages: number;
//       first_record: number;
//       last_record: number;
//       results: Lead[];
//     }>) => {
//       const { total_records, total_pages, first_record, last_record, results } = action.payload
//       state.leads = results;
//       state.totalRecords = total_records;
//       state.totalPages = total_pages;
//       state.firstRecord = first_record;
//       state.lastRecord = last_record;
//       state.isLoading = false;
//     },
//     fetchLeads: state => {
//       state.isLoading = true;
//     },
//     createLead: (state, action: PayloadAction<Lead>) => {
//       state.leads.push(action.payload);
//     },
//     updateLead: (state, action: PayloadAction<Lead>) => {
//       const updatedLead = action.payload;
//       const leadIndex = state.leads.findIndex(lead => lead.id === updatedLead.id);
//       if (leadIndex !== -1) {
//         state.leads[leadIndex] = updatedLead;
//       }
//     }
//   }
// });

// export const { createLead, updateLead, setLeads, fetchLeads } = leadSlice.actions;
// export const leadReducer: Reducer<LeadState> = leadSlice.reducer;
import { combineReducers } from 'redux';
import leadsReducer from './leadSlice';

const rootReducer = combineReducers({
  leads: leadsReducer,
});

export default rootReducer;
