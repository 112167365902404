import React from "react";

const DashboardPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center mt-10">
      <h1 className="text-3xl font-bold mb-6">Dashboard</h1>
      <div className="flex justify-center">
        <div className="w-64 h-64 bg-blue-500 rounded-md flex items-center justify-center text-white font-bold text-2xl">
          Total Leads: 100
        </div>
        <div className="w-64 h-64 bg-green-500 rounded-md flex items-center justify-center text-white font-bold text-2xl ml-6">
          New Leads: 10
        </div>
        <div className="w-64 h-64 bg-yellow-500 rounded-md flex items-center justify-center text-white font-bold text-2xl ml-6">
          Pending Tasks: 5
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
