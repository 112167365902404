import { BASE_URL } from "../../constants";
import api from "../../lib/axios";

export interface Assignee {
    id: number;
    name: string;
}

export type Task = {
    [key: string]: string | undefined | number | boolean | { name: string} | Assignee[];
    id: number;
    title?: string;
    status?: string;
    priority?: string;
    due_date?: string;
    description?: string;
    created_on?: string;
    updated_on?: string;
    created_by?: {
        name: string
    };
    updated_by?: {
        name: string
    };
    assigned_to?: Assignee[]
  };

export const getTasks = async (currentPage: number) => {
    const response = await api.get(`${BASE_URL}/tasks/list/?page=${currentPage}`)
    return response.data
}

export const getTaskWithID = async (id: number) => {
    const response = await api.get(`${BASE_URL}/tasks/${id}/`)
    return response.data
}

export const postTaskData = async (id: number, task: Task) => {
    const response = await api.put(`${BASE_URL}/tasks/${id}/`, task)
    return response.data
}

export const createTaskData = async (task: Task) => {
    const response = await api.post(`${BASE_URL}/tasks/`, task)
    return response.data
}

export const deleteTaskData = async (id: number) => {
    const response = await api.delete(`${BASE_URL}/tasks/${id}/`)
    return response.data
}

// export async function handleLeadResponse (data: LeadResponse) {
//     const response = await getLeads()

// }