interface Props {
  currentPage: number;
  totalPages: number;
  handlePageChange: (pageNumber: number) => void;
}

const PaginationControls = ({ currentPage, totalPages, handlePageChange }: Props) => {
  
  const renderPageButtons = () => {
    const buttons = [];
    const maxButtonsToShow = 10; // Define the maximum number of buttons to show
    const ellipsis = <span className="mx-1 text-white">...</span>;
  
    let startPage = Math.max(1, currentPage - Math.floor(maxButtonsToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxButtonsToShow - 1);
    startPage = Math.max(1, endPage - maxButtonsToShow + 1);
  
    // Render "First" button
    if (startPage > 1) {
      buttons.push(
        <button
          key="first"
          onClick={() => handlePageChange(1)}
          className="px-4 mx-1 rounded bg-teal-300"
        >
          1
        </button>
      );
      buttons.push(ellipsis);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-4 mx-1 rounded 
            ${currentPage === i ? 'active bg-orange-300' : 'bg-teal-300'}
          `}
          disabled={currentPage === i}
        >
          {i}
        </button>
      );
    }
  
    // Render "Last" button
    if (endPage < totalPages) {
      buttons.push(ellipsis);
      buttons.push(
        <button
          key="last"
          onClick={() => handlePageChange(totalPages)}
          className="px-4 mx-1 rounded bg-teal-300"
        >
          {totalPages}
        </button>
      );
    }
  
    return buttons;
  };

  return (
    <div className="flex items-center justify-center mx-auto my-6">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 0 || currentPage === 1}
        className={`${
          currentPage === 0 || currentPage === 1 ? 'bg-gray-300' : 'bg-teal-300'
        } px-4 rounded mx-2`}
      >
        Previous
      </button>
      {renderPageButtons()}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`${
          currentPage === totalPages ? 'bg-gray-300' : 'bg-teal-300'
        } px-4 rounded mx-2`}
      >
        Next
      </button>
    </div>
  );
};

export default PaginationControls;
