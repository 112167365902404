import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="text-center text-3xl font-extrabold text-gray-900">
            Oops! Page not found
          </h2>
        </div>
        <p className="text-center text-gray-500">
          Sorry, we couldn't find the page you were looking for.
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;
