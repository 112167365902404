import React from 'react';
import { Link } from 'react-router-dom';
import Cropway from '../../assets/logo/Cropway.png'

type Props = {
  auth: boolean
}

const LandingPage: React.FC<Props> = ({auth}) => {

  const ifLogin = () => {
    return (
      <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4">
          <Link
            to="app/create-lead"
            className="min-w-[150px] flex justify-center bg-sky-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mx-4 rounded focus:outline-none focus:shadow-outline"
          >
            Create Lead
          </Link>
          <Link
            to="app/leads"
            className="min-w-[150px] flex justify-center bg-emerald-600 hover:bg-emerald-800 text-white font-bold py-2 px-4 mx-4 rounded focus:outline-none focus:shadow-outline"
          >
            All Leads
          </Link>
        </div>
    )
  } 

  const ifNotLogin = () => {
    return (
      <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4">
          <Link
            to="auth/login"
            className="min-w-[150px] flex justify-center bg-sky-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mx-4 rounded focus:outline-none focus:shadow-outline"
          >
            Login
          </Link>
          <a
            href="auth/register"
            className="min-w-[150px] flex justify-center bg-emerald-600 hover:bg-emerald-800 text-white font-bold py-2 px-4 mx-4 rounded focus:outline-none focus:shadow-outline"
          >
            Register
          </a>
        </div>
    )
  }

  return (
    <div className="flex items-center justify-center bg-blue-200 dark:bg-gray-900 min-h-screen">
      <div className="container mx-auto py-10">
        <img src={Cropway} alt="cropway_logo" className="w-28 dark:bg-slate-100 rounded-lg mx-auto my-5" />
        <h1 className="text-4xl font-bold text-center text-gray-900 dark:text-teal-400 mb-6 mx-2">
          Welcome to CRM App
        </h1>
        <p className="text-lg text-center text-gray-900 dark:text-rose-400 mb-8 mx-4">
          A powerful CRM solution to manage your leads efficiently.
        </p>
        {auth ? ifLogin() : ifNotLogin()}
        
      </div>
    </div>
  );
};

export default LandingPage;
