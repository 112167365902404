import { Lead } from "../../../features/leads/api";

export const productInfo = {
  "id": 1,
  "name": "Product 1",
  "variety": "Variety 1",
  "category": "Category 1",
  "price_min": "10.00",
  "price_max": "20.00",
  "qty": 10,
  "qty_unit": "Units",
  "min_qty": 2,
  "min_qty_unit": "Units",
  "created_at": "2022-05-11T10:00:00Z",
  "updated_at": "2022-05-11T11:00:00Z",
  "created_by": {
    "name": "John Doe"
  },
  "updated_by": {
    "name": "Jane Doe"
  }
}

export const fieldsLead = [
  {
    name_: 'product.name',
    label: 'Product Name',
    type: 'selectDrop',
    options: [
      { id: 0, value: '', label: 'Select an option' },
    ],
  },
  {
    name_: 'product.variety',
    label: 'Product Variety',
    type: 'text',
  },
  {
    name_: 'product.category',
    label: 'Product Category',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'Crop', label: 'Crop' },
      { id: 2, value: 'Flower', label: 'Flower' },
      { id: 3, value: 'Oilseed', label: 'Oilseed' },
      { id: 4, value: 'Other', label: 'Other' },
    ],
  },
  {
    name_: 'product.price_min',
    label: 'Expected Minimum Price',
    type: 'number',
  },
  {
    name_: 'product.price_max',
    label: 'Expected Maximum Price',
    type: 'number',
  },
  {
    name_: 'product.qty',
    label: 'Quantity',
    type: 'number',
  },
  {
    name_: 'product.qty_unit',
    label: 'Unit (Quantity)',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'Kg', label: 'Kilogram' },
      { id: 2, value: 'MT', label: 'Metric Ton' },
    ],
  },
  {
    name_: 'product.min_qty',
    label: 'Expected Minimum Quantity',
    type: 'number',
  },
  {
    name_: 'product.min_qty_unit',
    label: 'Unit (Min. Qty.)',
    type: 'select',
    options: [
      { id: 0,  value: '', label: 'Select an option' },
      { id: 1,  value: 'Kg', label: 'Kilogram' },
      { id: 2,  value: 'MT', label: 'Metric Ton' },
    ],
  },
]

export const fields = [
  {
    name_: 'name',
    label: 'Product Name',
    type: 'text',
  },
  {
    name_: 'variety',
    label: 'Product Variety',
    type: 'text',
  },
  {
    name_: 'category',
    label: 'Product Category',
    type: 'select',
    options: [
      { id: 0, value: '', label: 'Select an option' },
      { id: 1, value: 'Crop', label: 'Crop' },
      { id: 2, value: 'Flower', label: 'Flower' },
      { id: 3, value: 'Oilseed', label: 'Oilseed' },
      { id: 4, value: 'Other', label: 'Other' },
    ],
  },
  {
    name_: 'price_min',
    label: 'Expected Minimum Price',
    type: 'number',
  },
  {
    name_: 'price_max',
    label: 'Expected Maximum Price',
    type: 'number',
  },
  {
    name_: 'qty',
    label: 'Quantity',
    type: 'number',
  },
  {
    name_: 'qty_unit',
    label: 'Unit (Quantity)',
    type: 'select',
    options: [
      { id: 0,  value: '', label: 'Select an option' },
      { id: 1,  value: 'Kg', label: 'Kilogram' },
      { id: 2,  value: 'MT', label: 'Metric Ton' },
    ],
  },
  {
    name_: 'min_qty',
    label: 'Expected Minimum Quantity',
    type: 'number',
  },
  {
    name_: 'min_qty_unit',
    label: 'Unit (Min. Qty.)',
    type: 'select',
    options: [
      { id: 0,  value: '', label: 'Select an option' },
      { id: 1,  value: 'Kg', label: 'Kilogram' },
      { id: 2,  value: 'MT', label: 'Metric Ton' },
    ],
  },
]
