import React, { SetStateAction } from "react";
import { fieldOption } from "../../../features/auth/api/location/location";
import { fieldTypeTask } from '../Task/constants'
import { fieldTypeLead } from "../Lead/constants";

export const updateFieldsCreate = <T extends fieldTypeLead | fieldTypeTask>(
  responseData: fieldOption[], 
  field_name: string, 
  setUpdatedFields: React.Dispatch<SetStateAction<T[]>>
) => {
  setUpdatedFields((prevFields) => {
    return prevFields.map((field) => {
      if ((field.type === 'select' || field.type === 'selectMulti') && field.name_ === field_name && field.options) {
        // Create a deep copy of the existing options array
        const existingOptions = JSON.parse(JSON.stringify(field.options));

        // Filter out the previous custom options (id === 0)
        const filteredOptions = existingOptions.filter((option: fieldOption) => option.id === 0);

        // Map the responseData to create new options
        const newOptions = responseData.map((option) => ({
          id: option.id,
          value: option.value,
          label: option.label,
        }));

        const mergedOptions = [...filteredOptions, ...newOptions];

        return { ...field, options: mergedOptions };
      }
      return field;
    });
  });
}