import React, { useEffect, useState } from "react";
import { Assignee, Lead } from "../../../features/leads/api";
import InputField, { DateInputField } from "../InputField";
import SelectField, { SelectFieldObject } from "../SelectField";
import { fields } from "./constants";
import PlaceholderField from "../PlaceholderField";
import { fieldOption, handleBlockOptions, handleDistrictOptions, handleStateOptions } from "../../../features/auth/api/location/location";
import { handleProfileOptions } from "../../../features/auth/api/accounts/profile";
import FormLayout from "../../Layout/FormLayout";

interface LeadDetailFormProps {
  lead: Lead | null
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void
  setProfileList: React.Dispatch<React.SetStateAction<Assignee[]>>
} 

const LeadDetailForm: React.FC<LeadDetailFormProps> = ({
  lead,
  handleInputChange,
  setProfileList,
}) => {

  const [updatedFields, setUpdatedFields] = useState(fields);
  const [stateOption, setStateOption] = useState(fields[11]?.options);
  const [districtOption, setDistrictOption] = useState(fields[12]?.options);
  
  const updateFieldsCreate = (responseData: fieldOption[], field_name: string) => {
    setUpdatedFields((prevFields) => {
      return prevFields.map((field) => {
        if ((field.type === 'select' || field.type === 'selectMulti') && field.name_ === field_name && field.options) {
          // Create a deep copy of the existing options array
          const existingOptions = JSON.parse(JSON.stringify(field.options));
  
          // Filter out the previous custom options (id === 0)
          const filteredOptions = existingOptions.filter((option: fieldOption) => option.id === 0);
  
          // Map the responseData to create new options
          const newOptions = responseData.map((option) => ({
            id: option.id,
            value: option.value,
            label: option.label,
          }));
  
          const mergedOptions = [...filteredOptions, ...newOptions];
  
          return { ...field, options: mergedOptions };
        }
        return field;
      });
    });
  
    if (field_name === "state"){
      setStateOption(responseData)
    } else if (field_name === "district") {
      setDistrictOption(responseData)
    }
  }

  const fetchProfileList = async() => {
    try {
      let {transformedArray, assigneeArr} = await handleProfileOptions();
      console.log(transformedArray)
      updateFieldsCreate(transformedArray, "assigned_to");
      setProfileList(assigneeArr)
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  }

  useEffect(() => {
    console.log("updatedFields", updatedFields)
    // console.log("State", lead?.state)
    // console.log("District", lead?.district)
  }, [updatedFields])

  const fetchDistrictOptions = async() => {

    if(lead?.state) {
      let id = updatedFields
                .filter(field => field.name_ === "state" && field.type === "select")
                .map(field => (field.options || [])
                .filter((option) => option.value === lead.state)
                .map((option) => option.id))[0][0]

      if(id){
        let id_state = id
        const districtOptions = await handleDistrictOptions(id_state)
        updateFieldsCreate(districtOptions, "district")
        return true
      }
      
    }
    return false
  }

  const fetchBlockOptions = async() => {

    if(lead?.district) {
      let id = updatedFields
                .filter(field => field.name_ === "district" && field.type === "select")
                .map(field => (field.options || [])
                .filter((option) => option.value === lead.district)
                .map((option) => option.id))[0][0]

      if(id){
        let id_district = id
        const blockOptions = await handleBlockOptions(id_district)
        updateFieldsCreate(blockOptions, "block")
        return true
      }
    }
    return false
  }

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const stateOptions = await handleStateOptions();
        updateFieldsCreate(stateOptions, "state");
        
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };
  
    fetchOptions();
    fetchProfileList();
  }, []);

  useEffect(() => {
    const fetchOptions = async () => {
      if (lead?.state && stateOption) {
  
        const districtOptionsFetched = await fetchDistrictOptions();
      }
    };
  
    if (stateOption) {
      fetchOptions();
    }
  }, [lead?.state, stateOption]);
  
  

  useEffect(() => {
    const fetchOptions = async () => {
      if (lead?.district && districtOption) {
  
        const blockOptionsFetched = await fetchBlockOptions();
      }
    };
  
    if (districtOption) {
      fetchOptions();
    }
  }, [lead?.district, districtOption]);
  
  useEffect(() => {
    // console.log(lead?.assigned_to && lead?.assigned_to[0])
  })

  return (
    <FormLayout title={lead?.title}>
      {updatedFields.map(({ name_, label, type, options, required }) => (
          <div key={name_} className="flex flex-col">
            {/* <label className="font-medium">{label}</label> */}
            
            {type === "select" ? (
              <SelectField 
                name_={name_.toString()}
                label={label}
                options={options}
                value={lead?.[name_]?.toString() ?? ""}
                onChange={handleInputChange}
              />
            ) : type === "placeholder" ? (
              <PlaceholderField 
                title={label}
                value={lead?.[name_]?.toString() ?? ""}
              />
            ): type === "selectMulti" ? (
              <SelectFieldObject 
                name_={name_.toString()}
                label={label}
                options={options}
                value={(Array.isArray(lead?.assigned_to) && lead?.assigned_to[0]?.id) ? lead?.assigned_to[0]?.id?.toString() : ""}
                onChange={handleInputChange}
              />
            ) : type === 'date' ? (
              <DateInputField
                name_={name_ as string}
                value={lead?.[name_]?.toString() ?? ''}
                label={label}
                onChange={handleInputChange}
              />
            ) : (
              <InputField 
                type={type}
                label={label}
                name_={name_.toString()}
                placeholder={name_.toString()}
                value={lead?.[name_]?.toString() ?? ""}
                required={required}
                onChange={handleInputChange}
              />
            )}
          </div>
        ))}
    </FormLayout>
  )
}

export default LeadDetailForm;