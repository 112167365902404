import React, { useEffect, useState } from 'react'
import { AppDispatch } from '../../redux/store/configureStore'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Task } from '../../features/tasks'
import { fetchTasks } from '../../redux/actions/taskAction'
import PageHeader from '../../components/Header/PageHeader'
import PaginationControls from '../../components/Pagination/PaginationControl'
import Loader from '../../components/Elements/Loader/Loader'
import { ArrowsUpDownIcon, ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/react/24/outline'
import { availableColumns, defaultSelectedColumns } from './constants'

type Props = {}

const Tasks = (props: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page')
  const encodedFilter = searchParams.get("filter")
  const decodedFilter = encodedFilter ? decodeURIComponent(encodedFilter) : ''

  const tasks_redux = useSelector((state: any) => state.tasks.tasks)
  
  const [tasks, setTasks] = useState<Task[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [firstRecord, setFirstRecord] = useState(0);
  const [lastRecord, setLastRecord] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [filterParams, setFilterParams] = useState("");

  const navigate = useNavigate();

  const [selectedColumns, setSelectedColumns] = useState<string[]>(defaultSelectedColumns);
  const [showColumnMenu, setShowColumnMenu] = useState(false);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc" | "">("");

  useEffect(() => {
    if(tasks_redux) {
      updateTasksData();
    }
  }, [tasks_redux, sortColumn, sortDirection])

  const updateTasksData = () => {
    setTasks(sortData(tasks_redux?.data.results))
    setFirstRecord(tasks_redux?.data.first_record)
    setLastRecord(tasks_redux?.data.last_record)
    setTotalRecord(tasks_redux?.data.total_records)
    setTotalPages(tasks_redux?.data.total_pages)
  }

  useEffect(() => {
    console.log("tasks", tasks)
    console.log("tasks", tasks_redux)
  })
  
  useEffect(() => {
    console.log("In")
    if (decodedFilter) {
      console.log("decodedFilter", decodedFilter)
      setFilterParams(decodedFilter);
    } else {
      setFilterParams('');
      dispatch(fetchTasks(currentPage))
    }

  }, [dispatch, currentPage, decodedFilter])

  useEffect(() => {
    setLoading(tasks_redux?.loading)
  }, [tasks_redux?.loading])

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
    let queryParams = new URLSearchParams(location.search);
    queryParams.set('page', String(pageNumber))
    navigate(`/app/tasks/?${queryParams.toString()}`)
  }
  
  const updateLayout = () => {
    // dispatch(updateSelectedColumns(selectedColumns))
    setShowColumnMenu(false)
  }

  const handleColumnToggle = (columnId: string) => {
    if (selectedColumns.includes(columnId)) {
      setSelectedColumns(selectedColumns.filter((id) => id !== columnId));
    } else {
      setSelectedColumns([...selectedColumns, columnId]);
    }
  };

  const ColumnMenu = () => (
    <div className="bg-white dark:bg-zinc-900 border border-cyan-700 p-4 rounded shadow-xl transform transition-all">
      <button
        className="absolute top-0 right-0 p-5 text-lg font-bold dark:text-slate-400"
        onClick={() => setShowColumnMenu(false)}
      >
        X
      </button>
      <h3 className="text-lg font-semibold mb-2 dark:text-gray-200">Select Columns:</h3>
      <div className="flex justify-between m-1 sm:min-w-[300px]">
        <div className="w-1/2 mx-4">
          <h4 className="font-semibold mb-2 dark:text-gray-300">Include:</h4>
          {selectedColumns.map((columnId) => (
            <div key={columnId} className="flex items-center mb-2">
              <input
                type="checkbox"
                checked
                onChange={() => handleColumnToggle(columnId)}
              />
              <span className="ml-2 dark:text-gray-300">
                {
                  availableColumns.find((column) => column.id === columnId)
                    ?.label
                }
              </span>
            </div>
          ))}
        </div>
        <div className="w-1/2 mx-4">
          <h4 className="font-semibold mb-2 dark:text-gray-300">Exclude:</h4>
          {availableColumns.map(
            (column) =>
              !selectedColumns.includes(column.id) && (
                <div key={column.id} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    onChange={() => handleColumnToggle(column.id)}
                  />
                  <span className="ml-2 dark:text-gray-300">{column.label}</span>
                </div>
              )
          )}
        </div>
      </div>
      <div className="flex justify-end border-t border-dashed border-cyan-300">
        <button 
          className="bg-blue-500 dark:text-gray-200 p-2 rounded-lg mt-4"
          onClick={updateLayout}
        >
          Save the layout
        </button>
      </div>
    </div>
  );

  const sortData = (data: Task[]) => {
    if (!data || !Array.isArray(data)) {
      return [];
    }

    const sortedData = [...data];

    if (sortDirection) {
      sortedData.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (valueA && valueB) {
          if (typeof valueA === "string" && typeof valueB === "string") {
            return valueA.localeCompare(valueB);
          }

          if (valueA < valueB) {
            return -1;
          }

          if (valueA > valueB) {
            return 1;
          }
        }

        return 0;
      });

      if (sortDirection === "desc") {
        sortedData.reverse();
      }
    }

    return sortedData;
  };

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const TableComponent = () => {
    return (
      <>
        <div className="container flex justify-end mx-auto">
          {tasks && tasks.length ? <span className="italic dark:text-slate-300 md:mx-10 mx-5 mb-4">
            {firstRecord} - {lastRecord} of {totalRecord} tasks
          </span> : 
          <span className="italic dark:text-slate-300 md:mx-10 mx-5 mb-4">
            Tasks not found
          </span>
          }
        </div>
        <div className="overflow-x-auto">
          <table className="mx-auto">
            <thead className="bg-blue-100 dark:bg-gray-800">
              <tr>
                {selectedColumns.map((columnId) => (
                  <th
                    key={columnId}
                    scope="col"
                    className="min-w-[100px] md:min-w-[200px] px-6 py-3 text-left border border-dashed border-gray-600 dark:border-slate-300 text-xs font-medium text-gray-700 dark:text-slate-300 uppercase tracking-wider"
                    onClick={() => handleSort(columnId)}
                  >
                    <div className="flex items justify-center">
                      <div className="mt-1">
                        {
                          availableColumns.find(
                            (column) => column.id === columnId
                          )?.label
                        }
                      </div>
                      {columnId === sortColumn && (
                        <span className="ml-3">
                          {sortDirection === "asc" ? (
                            <ChevronDoubleUpIcon className="w-4 h-4" />
                          ) : (
                            <ChevronDoubleDownIcon className="w-4 h-4" />
                          )}
                        </span>
                      )}
                      <div>
                        {columnId !== sortColumn && (
                          <ArrowsUpDownIcon className="w-4 h-6 ml-3" />
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            {!loading ? (
              <tbody className="bg-blue-100 dark:bg-gray-900 divide-y divide-gray-600 dark:divide-gray-200">
                {tasks && tasks.map((task, index) => (
                  <tr
                    key={index}
                    className="cursor-pointer hover:bg-indigo-100 dark:hover:bg-gray-700"
                    onClick={() => navigate(`/app/task/${task.id}`)}
                  >
                    {selectedColumns.map((columnId) => (
                      <td
                        key={columnId}
                        className="px-6 py-4 whitespace-nowrap dark:text-gray-300 border border-dashed border-slate-600 dark:border-slate-300"
                      >
                        {columnId === "assigned_to" ? (
                          // Special handling for the 'assigned_to' field
                          task && task[columnId] ? (
                            task[columnId]?.map((assignee , assigneeIndex) => (
                              <span key={assigneeIndex}>
                                {assignee.name}
                                {assigneeIndex !== (task[columnId]?.length ?? 0) - 1 ? ", " : ""}
                              </span>
                            ))
                          ) : (
                            "N/A" // Or any other default value when 'assigned_to' is undefined
                          )
                        ) : (
                          // For other fields, just convert to string
                          task[columnId]?.toString()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            ) : (
              <Loader show={true} />
            )}
          </table>
        </div>
      </>
    );
  };


  return (
    <div className='flex'>
      <main className='bg-blue-200 dark:bg-gray-900 w-full min-h-screen'>
        <PageHeader
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          handleCreate={() => navigate('app/create-task')}
          handleShowColumnMenu={() => setShowColumnMenu(!showColumnMenu)}
        />

        {showColumnMenu && (
          <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50'>
            
          </div>
        )}

        <TableComponent />

        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </main>
    </div>
  )
}

export default Tasks