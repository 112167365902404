import { useEffect, useState } from "react";
import { Product } from "../../../features/products/api";
import { InputProductField } from "../InputField";
import { fieldsLead } from "../Product/constants";
import { SearchableDropdown, SelectProductField } from "../SelectField";
import { fieldOption, handleProductNameOptions } from "../../../features/auth/api/location/location";

type Props = {
    index: number;
    product: Product
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    handleDelete: (index: number) => void
    handleProductName: (selectedOption: number, name_: string, index: number) => void;
};

const AddProductForm = ({ index, product, handleInputChange, handleDelete, handleProductName }: Props) => {

  const [updatedFields, setUpdatedFields] = useState(fieldsLead)
  const [productOptions, setProductOptions] = useState(fieldsLead[3]?.options)

  const updateFieldsCreate = (responseData: fieldOption[], field_name: string) => {
    const updatedFieldsFn = updatedFields.map((field) => {
      if (field.type === "selectDrop" && field.name_ === field_name && field.options) {
        const existingOptions = {
          ...field,
          options: field.options.filter((option) => option.id === 0),
        };
        return { ...field, options: [
          ...existingOptions.options,
          ...responseData.map((option) => ({
            id: option.id,
            value: option.value,
            label: option.label
          }))
        ]}
      }
      return field;
    });

    setUpdatedFields(updatedFieldsFn)

    if(field_name === "product.name"){
      setProductOptions(responseData)
    }
  }

  useEffect(() => {
    const fetchProductName = async() => {
      const productNameOptions = await handleProductNameOptions();
      updateFieldsCreate(productNameOptions, "product.name")
    }
    fetchProductName();
  }, [])  
  
  const fieldsMap = updatedFields.map(({ name_, label, type, options }) => {
    const fieldName = name_.slice(8);
    const productValue = product[fieldName]?.toString()
    return (
      <div key={name_} className="flex flex-row ">
        {type === "select" ? (
          <SelectProductField
            name_={fieldName}
            label={label}
            index={index}
            options={options}
            value={productValue ? productValue?.toString() : ""}
            onChange={handleInputChange}
          />
        ) : type === "selectDrop" ? (
            <SearchableDropdown 
                value={productValue ? productValue?.toString() : ""}
                name_={fieldName}
                index={index}
                label={label} 
                options={options}
                placeholder="Select an option"
                handleProductName={handleProductName}
              />
        ) : (
          <InputProductField
            type={type}
            label={label}
            index={index}
            name_={fieldName}
            placeholder={name_}
            value={productValue ? productValue?.toString() : ""}
            onChange={handleInputChange}
          />
        )}
      </div>
    );
  });

  return (
    <div className="flex flex-wrap justify-center items-center">
      <div className="bg-sky-100 dark:bg-gray-600 rounded-lg shadow-lg px-5 pt-5">
      <h2 className="text-lg font-medium mb-5 dark:text-purple-200">Product Information - {index + 1}</h2>
      <div className="flex flex-row flex-wrap gap-5">
        {fieldsMap}
        </div>
        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mt-5 rounded" onClick={() => handleDelete(index)}>
          Delete
        </button>
        <hr className=" mt-5 border-2 rounded-lg border-cyan-200" />
      </div>
    </div>
  );
};

export default AddProductForm;
