import React from "react";

import { useEffect, useState } from "react";
import Loader from "../../components/Elements/Loader/Loader";
import { Assignee, createLeadData, deleteLeadData, getLeadWithID, Lead, postLeadData } from "../../features/leads/api";
import LeadForm from "../../components/Form/Lead/LeadForm";
// import ConfirmData from "../../components/Elements/Popup/ConfirmPopupForm";
import ConfirmData from "../../components/Elements/Popup/ConfirmPopupFormGeneric";
// import ProductForm from "../../components/Form/Product/ProductForm";
import { Product } from "../../features/products/api";
import ProductEntryForm from "../../components/Form/Lead/ProductEntryForm";
import { useNavigate, useParams } from "react-router-dom";
import LeadDetailForm from "../../components/Form/Lead/LeadDetailForm";
import { showErrorMessage, showMessage } from "../../components/Elements/Message";
import axios from "axios";

const LeadCreateDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [lead, setLead] = useState<Lead | null>(null)
  const [product, setProduct] = useState<Product[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = React.useState(false);
  const [titlePopup, setTitlePopup] = useState<string>("")
  const [profileList, setProfileList] = useState<Assignee[]>([{id: 0, name: ""}]);

  useEffect(() => {
    const fetchLead = async () => {
        if (id) {
          setLoading(true)
          try {
              const response = await getLeadWithID(parseInt(id));
              setLead(response);
          } catch (error) {
              console.error(error)
              if (axios.isAxiosError(error)) {
                const apiError = error.response?.data;
                console.log("apiError type: ", typeof(apiError))
                console.log("apiError: ", apiError)
                showErrorMessage(apiError.errors)
                
                // Handle API error...
              } else {
                // Handle other types of errors...
              }
          } finally {
              setLoading(false)
          }
        }
      };
      fetchLead();
  }, [id])

  useEffect(() => {
    console.log("Lead", lead)
    if (lead && lead.product) {
        setProduct(lead.product)
    }
  }, [lead])

  const updateLead = async () => {
    setLoading(true)
    try {
      if (lead){
          let response = await postLeadData(lead.id, lead);
          showMessage(response.message)
          // console.log(response.message)
          navigate("/app/leads/")
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const apiError = error.response?.data;
        showErrorMessage(apiError.errors)
        
        // Handle API error...
      } else {
        // Handle other types of errors...
      }
    }  finally {
        setLoading(false)
        setIsOpen(false)
    }
  }

  const deleteLead = async () => {
    setLoading(true)
    try {
      if (lead){
          let response = await deleteLeadData(lead.id);
          showMessage(response.message)
          navigate("/app/leads/")
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const apiError = error.response?.data;
        console.log("apiError type: ", typeof(apiError))
        console.log("apiError: ", apiError)
        showErrorMessage(apiError.errors)
        
        // Handle API error...
      } else {
        // Handle other types of errors...
      }
    } finally {
        setLoading(false)
    }
  }

  const createLead = async (lead: Lead) => {
    setLoading(true)
    try {
      if (lead){
        const response = await createLeadData(lead)
        showMessage(response.message)
        setLead(null)
        return true
      } 
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const apiError = error.response?.data;
        console.log("apiError type: ", typeof(apiError))
        console.log("apiError: ", apiError)
        showErrorMessage(apiError.errors)
        
        // Handle API error...
      } else {
        // Handle other types of errors...
      }
      return false   
    } finally {
      setLoading(false)
      setIsOpen(false)
    }
  };

  const handleInputChangeProduct = (event:  React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    const index = parseInt(event.target.getAttribute("data-index") as string);
    setProduct((prevProducts) => {
      const newProducts = [...prevProducts];
      newProducts[index][name] = value;
      return newProducts;
    });

    onSubmitProduct()
  };

  const handleProductName = (selectedOption: number, name_: string, index: number) => {
    setProduct((prevProducts) => {
      const newProducts = [...prevProducts];
      newProducts[index][name_] = selectedOption;
      return newProducts;
    });

    onSubmitProduct()
  }

  const handleAddProduct = (product: Product) => {
    setProduct((prev) => [...prev, product]);
  };

  const handleDeleteProduct = (index: number) => {
    setProduct((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  const handleInputChange = async(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, selectedOption?: Assignee[]) => {
    const { name, value } = event.target;
    const assignee = [profileList.find((option) => option.id === parseInt(value))]
    if (name.startsWith("product.")) {
      setLead((prevState: Lead | null) => {
        return {
          ...(prevState ?? {}),
          product: {
            ...(prevState?.product ?? {}),
            [name.slice(8)]: value,
          },
        } as Lead | null;
      });
    } else {
      setLead((prevState: Lead | null) => {
        return {
          ...(prevState ?? {}),
          [name]: value,
          assigned_to: assignee,
        } as Lead | null;
      });
    }
  };

  const onSubmitProduct = () => {
    setLead((prevState: Lead | null) => {
      return {
        ...(prevState ?? {}),
          product : product
      } as Lead | null
    })
  }

  const handleSaveClick = async () => {  
    setIsOpen(true)
    if (id){
        setTitlePopup("Are you sure you want to update this lead...!!!")
    } else {
      setTitlePopup("Are you sure you want to create this lead...!!!")
    }
  }

  const handleDeleteClick = async () => {
    setTitlePopup("Are you sure you want to delete this lead...!!!")
    setIsOpen(true)
  }

  const handleConfirmClick = async () => {
    // setLoading(true)
    if (id) {
        if (titlePopup === "Are you sure you want to update this lead...!!!"){
            let updateLeadfn = await updateLead()
            // if (updateLeadfn){
            //     setLoading(false)
            // }
          } else {
            let deleteLeadfn = await deleteLead()
          }
    } else {  
    let new_lead = lead && createLead(lead)
    }
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  if (!id && loading) {
    return <Loader show={true} />
  }

  if (id){
    if (!lead || loading) {
        return <Loader show={true} />
    }
  }

  return (
  <div className="flex flex-col sm:m-5">
      <h1 className="text-5xl sm:text-9xl font-black mt-5 dark:text-teal-600">{id ? "Lead Details" : "Create Lead"}</h1>
      <div className="bg-blue-200 dark:bg-gray-700 rounded-lg shadow-lg">
      {id ? 
      <LeadDetailForm 
      lead={lead}
      handleInputChange={handleInputChange}
      setProfileList={setProfileList}
    />
      :<LeadForm 
        lead={lead} 
        handleInputChange={handleInputChange} 
        handleSaveClick={handleSaveClick}
        setProfileList={setProfileList}
      />}
      <ProductEntryForm
        products={product}
        handleInputChange={handleInputChangeProduct}
        handleAddProduct={handleAddProduct}
        handleDeleteProduct={handleDeleteProduct}
        handleProductName={handleProductName}
        onSubmit={onSubmitProduct}
      />
      </div>
      <div className="flex flex-row justify-between mx-5 my-10">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleSaveClick}>
        Save
        </button>
        {id && <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={handleDeleteClick}>
        Delete
        </button>}
      </div>
      {isOpen && (
        <ConfirmData<Lead>
          title = {titlePopup}
          data={JSON.stringify(lead)} 
          onCancel={onCancel} 
          onSave={handleConfirmClick} 
          loading={loading} 
        />
      )}
  </div>
  );
}

export default LeadCreateDetailPage;